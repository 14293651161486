import { computed, defineComponent, PropType } from 'vue'
import VipInlichtingVanToepassing
  from '@/components/vip-inlichting/components/vip-inlichting-van-toepassing/vip-inlichting-van-toepassing.vue'
import {
  BeleidsplanInlichting,
  BeleidsplanInlichtingPlanType,
  BeleidsplanInput,
  BeleidsplanInputPlanType,
  BronInformatie,
  DossierStatus,
  Inlichting,
  InlichtingenIndicator,
  InlichtingRubriek,
  TypeInlichting,
  VipInlichtingDiscriminator,
  VipInlichtingType
} from '@/infrastructure/bff-client/bff-client'
import VipInlichtingPreviewNew
  from '@/components/vip-inlichting/new-version/components/inlichting-preview/vip-inlichting-preview-new.vue'
import { formatDate, formatNumber } from '@/infrastructure/filters/filters'
import { inlichtingen } from '@/infrastructure/constants/inlichting-types-constants'
import useEditableInlichting from '@/components/vip-inlichting/new-version/composables/editable-inlichting.composable'
import EventBus from '@/infrastructure/events/event-bus'
import VipInlichtingEdit
  from '@/components/vip-inlichting/new-version/components/inlichting-edit/vip-inlichting-edit.vue'

function mapInlichtingToInput (vipInlichting?: BeleidsplanInlichting): BeleidsplanInput {
  return {
    inlichtingenIndicator: vipInlichting === null ? InlichtingenIndicator.NEE : vipInlichting.inlichtingenIndicator,
    discriminator: VipInlichtingDiscriminator.BELEIDSPLAN_V1,
    inlichtingType: VipInlichtingType.BELEIDSPLAN,
    beschrijving: vipInlichting?.beschrijving,
    planType: vipInlichting?.planType as unknown as BeleidsplanInputPlanType,
    datumOpmaak: vipInlichting?.datumOpmaak,
    impactPerceel: vipInlichting?.impactPerceel,
    externeDocumentatie: vipInlichting?.externeDocumentatie
  } as BeleidsplanInput
}

function mapInputToInlichting (input: BeleidsplanInput, inlichtingId: string): BeleidsplanInlichting {
  return {
    inlichtingId,
    inlichtingenIndicator: input.inlichtingenIndicator as unknown as InlichtingenIndicator,
    inlichtingType: VipInlichtingType.BELEIDSPLAN,
    version: 1,
    bronInformatie: {
      informatieverstrekker: 'Lokaal bestuur',
      laatsteWijzigingTijdstip: new Date()
    } as BronInformatie,
    kaarten: [],
    rubriek: InlichtingRubriek.OVERHEIDSPLANNEN,
    beschrijving: input.beschrijving,
    planType: input.planType as unknown as BeleidsplanInlichtingPlanType,
    datumOpmaak: input.datumOpmaak,
    impactPerceel: input.impactPerceel,
    externeDocumentatie: input.externeDocumentatie
  } as BeleidsplanInlichting
}

export default defineComponent({
  name: 'Beleidsplan',
  components: { VipInlichtingVanToepassing, VipInlichtingPreviewNew, VipInlichtingEdit },
  props: {
    inlichting: {
      type: Object as PropType<Inlichting>,
      required: false
    },
    dossierId: {
      type: String,
      required: true
    },
    dossierStatus: {
      type: Number as PropType<DossierStatus>,
      required: false
    },
    canDelete: Boolean
  },
  setup (props, { emit }) {
    const {
      refs,
      inlichtingKey,
      vipInlichting,
      inlichtingInput,
      canEdit,
      validationErrors,
      accordion,
      saving,
      addedBijlagen,
      bijlageUploaded,
      toggleIndicator,
      inlichtingToggled,
      cancelEdit,
      saveInlichting,
      validationFailed,
      downloadUrl,
      bijlagenSaved,
      bijlageDeleted
    } = useEditableInlichting(
      props.inlichting ?? ({ inlichtingType: VipInlichtingType.BELEIDSPLAN } as unknown as BeleidsplanInlichting),
      mapInlichtingToInput,
      mapInputToInlichting)

    const inlichtingType = TypeInlichting.Beleidsplan
    const inlichtingTitle = computed(() => inlichtingen.find(inl => inl.inlichtingType === inlichtingType).label)

    const options = new Map([
      ['VISIENOTA', 'Visienota'],
      ['GABARIETENPLAN', 'Gabarietenplan'],
    ])
    const validateInput = (): Map<string, string> => {
      const errors = new Map<string, string>()
      if (!inlichtingInput.value.beschrijving) {
        errors.set('plantype', 'Type plan is verplicht')
      }
      if (!inlichtingInput.value.beschrijving) {
        errors.set('beschrijving', 'Beschrijving is verplicht')
      }
      return errors
    }

    const saved = (inlichtingId: string) => {
      saveInlichting(inlichtingId)
      emit('saved', vipInlichting.value)
      inlichtingToggled(true)
    }

    const removed = () => {
      emit('removed', vipInlichting.value.inlichtingId)
      EventBus.$emit('close-modal', 'vl-modal-backdrop')
    }

    const cancelled = () => {
      cancelEdit()
      emit('cancelled')
    }

    return {
      vipInlichting,
      inlichtingInput,
      validationErrors,
      saving,
      accordion,
      refs,
      inlichtingKey,
      canEdit,
      addedBijlagen,
      inlichtingType,
      inlichtingTitle,
      options,
      bijlageUploaded,
      inlichtingToggled,
      toggleIndicator,
      validateInput,
      validationFailed,
      saved,
      removed,
      cancelled,
      downloadUrl,
      bijlagenSaved,
      bijlageDeleted,
      formatNumber,
      formatDate
    }
  }
})
