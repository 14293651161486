import { computed, defineComponent, PropType, ref, watch } from 'vue'
import { formatDate, formatNumber } from '@/infrastructure/filters/filters'
import {
  BeroepsprocedureMilieu,
  Document, InlichtingenIndicator, MilieuBeslissingEersteAanleg,
  MilieuBeslissingEersteAanlegStatus,
  MilieuRisicoklasse,
  MilieuvergunningInput,
  MilieuVergunningProcedure,
  MilieuVergunningsStatus,
  MilieuVergunningVerlenendeInstantie,
  VergunningActiviteit
} from '@/infrastructure/bff-client/bff-client'
import useMilieuVergunningLabels
  from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/milieuvergunning/composables/milieuvergunning-labels'
import VipDatePickerWrapper from '@/components/vip-date-picker-new/vip-date-picker-wrapper.vue'
import ContentCollection from '@/components/vip-inlichting/new-version/components/content-collection/content-collection.vue'
import BeroepsprocedureMilieuComponent
  from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/milieuvergunning/edit/beroepsprocedure/beroepsprocedure-milieu.vue'
import MilieuActiviteit
  from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/milieuvergunning/edit/activiteiten/milieu-activiteit.vue'
import EventBus from '@/infrastructure/events/event-bus'

export default defineComponent({
  name: 'milieuvergunning-edit',
  components: {
    MilieuActiviteit,
    BeroepsprocedureMilieu: BeroepsprocedureMilieuComponent,
    ContentCollection,
    VipDatePickerWrapper
  },
  props: {
    input: Object as PropType<MilieuvergunningInput>,
    bijlagen: Array as PropType<Document[]>,
    inlichtingId: String,
    dossierId: String,
    inlichtingKey: String,
    validationErrors: Map<string, string>,
    saving: Boolean,
  },
  setup (props, { emit }) {
    const { options, getLabel } = useMilieuVergunningLabels()
    const inlichtingInput = ref(props.input)
    const beslissingInput = ref(props.input.beslissing ?? {} as MilieuBeslissingEersteAanleg)

    const optionsMilieuVergunningStatus = ['', ...Object.values(MilieuVergunningsStatus)]
    const optionsVergunningVerlenendeInstantie = ['', ...Object.values(MilieuVergunningVerlenendeInstantie)]
    const optionsStatusBeslissing = Object.values(MilieuBeslissingEersteAanlegStatus)
    const optionsToegepasteWetgeving = Object.values(MilieuVergunningProcedure)
    const optionsRisicoKlasse = ['', ...Object.values(MilieuRisicoklasse)]

    const beslistOpRequired = computed(() => {
      return inlichtingInput.value.beslissing.status !== MilieuBeslissingEersteAanlegStatus.GEEN_BESLISSING
    })

    const beroepAangetekend = computed({
      get () {
        return inlichtingInput.value.inlichtingenIndicator === InlichtingenIndicator.JA &&
            (inlichtingInput.value.beroepsprocedures ?? []).length > 0
      },
      set (value) {
        const input = structuredClone(inlichtingInput.value)
        if (value) {
          if ((input.beroepsprocedures ?? []).length === 0) {
            input.beroepsprocedures = [{}]
          }
        } else {
          input.beroepsprocedures = []
        }
        inlichtingInput.value = input
      },
    })

    const bijlageUploaded = (bijlagen: File[]) => {
      emit('bijlage-uploaded', bijlagen)
    }

    const proceduresUpdated = (input: MilieuvergunningInput) => {
      inlichtingInput.value = input
    }

    const addBeroepsProcedure = () => {
      const input = structuredClone(inlichtingInput.value)
      input.beroepsprocedures.push({})
      inlichtingInput.value = input
    }

    const removeBeroepsProcedure = (index: number) => {
      const input = structuredClone(inlichtingInput.value)
      input.beroepsprocedures.splice(index, 1)
      inlichtingInput.value = input
      EventBus.$emit('close-modal', 'vl-modal-backdrop')
    }

    const updateBeroep = (newValue: { procedure: BeroepsprocedureMilieu, index: number }) => {
      const input = structuredClone(inlichtingInput.value)
      input.beroepsprocedures[newValue.index] = newValue.procedure
      inlichtingInput.value = input
    }

    const addActiviteit = () => {
      const input = structuredClone(inlichtingInput.value)
      if (input.activiteiten == null) {
        input.activiteiten = []
      }
      input.activiteiten.push({})
      inlichtingInput.value = input
    }

    const removeActiviteit = (index: number) => {
      const input = structuredClone(inlichtingInput.value)
      input.activiteiten.splice(index, 1)
      inlichtingInput.value = input
      EventBus.$emit('close-modal', 'vl-modal-backdrop')
    }

    const updateActiviteit = (activiteit: VergunningActiviteit, index: number) => {
      const input = structuredClone(inlichtingInput.value)
      input.activiteiten[index] = activiteit
      inlichtingInput.value = input
    }

    watch(beslissingInput, (newValue) => {
      inlichtingInput.value.beslissing = newValue
    }, { deep: true })

    watch(inlichtingInput, (newValue) => {
      emit('input-updated', newValue)
    }, { deep: true })

    return {
      inlichtingInput,
      beslissingInput,
      beroepAangetekend,
      options,
      optionsMilieuVergunningStatus,
      optionsVergunningVerlenendeInstantie,
      optionsStatusBeslissing,
      optionsToegepasteWetgeving,
      optionsRisicoKlasse,
      beslistOpRequired,
      addBeroepsProcedure,
      addActiviteit,
      removeBeroepsProcedure,
      removeActiviteit,
      formatNumber,
      getLabel,
      formatDate,
      bijlageUploaded,
      proceduresUpdated,
      updateBeroep,
      updateActiviteit
    }
  },
})
