import { computed, defineComponent, onMounted, PropType, ref, watch } from 'vue'
import VipInlichtingVanToepassing
  from '@/components/vip-inlichting/components/vip-inlichting-van-toepassing/vip-inlichting-van-toepassing.vue'
import { BffClientFactory } from '@/infrastructure/bff-client/bff-client-handler'
import { InlichtingenIndicator, InlichtingInput } from '@/infrastructure/bff-client/bff-client'
import { useDossierStore } from '@/infrastructure/stores/dossier-store'
import { catchBffError } from '@/infrastructure/helpers/general'
import {
  BijlageUploadResult,
  VipInlichtingBijlagenService
} from '@/components/vip-inlichting/vip-inlichting-bijlagen-service'
import InlichtingActions
  from '@/components/vip-inlichting/new-version/components/inlichting-actions/inlichting-actions.vue'

export default defineComponent({
  name: 'VipInlichtingEdit',
  components: { VipInlichtingVanToepassing, InlichtingActions },
  props: {
    inlichtingTitle: String,
    inlichtingInput: Object as PropType<InlichtingInput>,
    inlichtingId: {
      type: String,
      required: false
    },
    canBeDeleted: Boolean,
    validate: {
      type: Function,
      required: true
    },
    bijlagen: Array as PropType<Array<File>>,
    validationErrors: Map as PropType<Map<string, string>>
  },
  setup (props, { emit }) {
    const isVanToepassing = ref(null)
    const originalInput = ref(null)
    const isSaving = ref(false)
    const errors = ref(props.validationErrors)
    const isRemoving = ref(false)

    const dossierStore = useDossierStore()

    const editActive = computed(() => {
      return isVanToepassing.value !== null ? isVanToepassing.value : (props.inlichtingInput.inlichtingenIndicator ?? InlichtingenIndicator.NEE) === InlichtingenIndicator.JA
    })

    async function remove () {
      try {
        isRemoving.value = true
        await BffClientFactory().dossierInlichtingen_Delete(dossierStore.dossierModuleGetDossierId, props.inlichtingId)
        emit('removed', props.inlichtingId)
      } catch (error) {
        catchBffError(error, 'Inlichting verwijderen', true, true)
      } finally {
        isRemoving.value = false
      }
    }

    const save = async () => {
      if (isVanToepassing.value) {
        errors.value = props.validate()
        if (errors.value.size > 0) {
          emit('validation-failed', errors.value)
          return
        }
      }
      isSaving.value = true
      emit('saving')
      if (props.inlichtingId) {
        BffClientFactory().dossierInlichtingen_Wijzig(props.inlichtingInput, dossierStore.dossierModuleGetDossierId, props.inlichtingId)
          .then(() => {
            saveBijlagen(dossierStore.dossierModuleGetDossierId, props.inlichtingId, props.bijlagen)
              .then(result => {
                emit('bijlagen-saved', result)
              })
              .finally(() => {
                emit('saved', props.inlichtingId)
                isSaving.value = false
              })
          })
          .catch((error) => {
            catchBffError(error, 'Inlichting bijwerken', true, true)
          })
      } else {
        BffClientFactory().dossierInlichtingen_VoegToe(props.inlichtingInput, dossierStore.dossierModuleGetDossierId)
          .then((response) => {
            saveBijlagen(dossierStore.dossierModuleGetDossierId, response.inlichtingId, props.bijlagen)
              .then(result => {
                emit('bijlagen-saved', result)
              })
              .finally(() => {
                emit('saved', response.inlichtingId)
                isSaving.value = false
              })
          })
          .catch((error) => {
            catchBffError(error, 'Inlichting toevoegen', true, true)
          })
      }
    }

    const saveBijlagen = async (dossierId: string, inlichtingId: string, bijlagen: File[]): Promise<BijlageUploadResult[]> => {
      const bijlagenService = new VipInlichtingBijlagenService()
      return await bijlagenService.save(dossierId, inlichtingId, bijlagen)
    }

    const cancel = () => {
      isVanToepassing.value = originalInput.value.inlichtingenIndicator === InlichtingenIndicator.JA
      isRemoving.value = false
      emit('cancelled')
    }

    watch(() => isVanToepassing.value, (newValue: boolean) => {
      emit('toggled', newValue)
    })

    onMounted(() => {
      if (props.inlichtingInput.inlichtingenIndicator === InlichtingenIndicator.JA) {
        isVanToepassing.value = true
      } else if (props.inlichtingInput.inlichtingenIndicator === InlichtingenIndicator.NEE) {
        isVanToepassing.value = false
      }
      originalInput.value = props.inlichtingInput
    })

    return {
      isVanToepassing,
      editActive,
      errors,
      isSaving,
      isRemoving,
      remove,
      save,
      cancel
    }
  }
})
