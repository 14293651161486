import { computed, defineComponent, PropType, ref, watch } from 'vue'
import VipInlichtingVanToepassing
  from '@/components/vip-inlichting/components/vip-inlichting-van-toepassing/vip-inlichting-van-toepassing.vue'
import {
  BronInformatie,
  DossierStatus,
  Inlichting,
  InlichtingenIndicator,
  InlichtingRubriek,
  PlanbatenOfSchadeInlichting,
  PlanbatenOfSchadeInlichtingCompensatieType,
  PlanbatenOfSchadeInlichtingNieuweBestemming,
  PlanbatenOfSchadeInlichtingOorspronkelijkeBestemming,
  PlanbatenOfSchadeInput,
  PlanbatenOfSchadeInputCompensatieType,
  PlanbatenOfSchadeInputNieuweBestemming,
  PlanbatenOfSchadeInputOorspronkelijkeBestemming,
  PlanbatenOfSchadePlanfase,
  TypeInlichting,
  VipInlichtingDiscriminator,
  VipInlichtingType
} from '@/infrastructure/bff-client/bff-client'
import VipInlichtingPreviewNew
  from '@/components/vip-inlichting/new-version/components/inlichting-preview/vip-inlichting-preview-new.vue'
import { formatDate, formatNumber } from '@/infrastructure/filters/filters'
import { inlichtingen } from '@/infrastructure/constants/inlichting-types-constants'
import useEditableInlichting from '@/components/vip-inlichting/new-version/composables/editable-inlichting.composable'
import EventBus from '@/infrastructure/events/event-bus'
import VipInlichtingEdit
  from '@/components/vip-inlichting/new-version/components/inlichting-edit/vip-inlichting-edit.vue'

function mapInlichtingToInput (vipInlichting?: PlanbatenOfSchadeInlichting): PlanbatenOfSchadeInput {
  return {
    inlichtingenIndicator: vipInlichting === null ? InlichtingenIndicator.NEE : vipInlichting.inlichtingenIndicator,
    discriminator: VipInlichtingDiscriminator.PLANBATEN_OF_SCHADE_V1,
    inlichtingType: VipInlichtingType.PLANBATEN_OF_SCHADE,
    beschrijving: vipInlichting?.beschrijving,
    referentie: vipInlichting?.referentie,
    compensatieType: vipInlichting?.compensatieType as unknown as PlanbatenOfSchadeInputCompensatieType,
    oorspronkelijkeBestemming: vipInlichting?.oorspronkelijkeBestemming as unknown as PlanbatenOfSchadeInputOorspronkelijkeBestemming,
    nieuweBestemming: vipInlichting?.nieuweBestemming as unknown as PlanbatenOfSchadeInputNieuweBestemming,
    dossiertype: vipInlichting?.dossiertype,
    dossiernummer: vipInlichting?.dossiernummer,
    externeDocumentatie: vipInlichting?.externeDocumentatie
  } as PlanbatenOfSchadeInput
}

function mapInputToInlichting (input: PlanbatenOfSchadeInput, inlichtingId: string): PlanbatenOfSchadeInlichting {
  return {
    inlichtingId,
    inlichtingenIndicator: input.inlichtingenIndicator as unknown as InlichtingenIndicator,
    inlichtingType: VipInlichtingType.PLANBATEN_OF_SCHADE,
    version: 1,
    bronInformatie: {
      informatieverstrekker: 'Lokaal bestuur',
      laatsteWijzigingTijdstip: new Date()
    } as BronInformatie,
    kaarten: [],
    rubriek: InlichtingRubriek.OVERHEIDSPLANNEN,
    beschrijving: input.beschrijving,
    referentie: input.referentie,
    compensatieType: input.compensatieType as unknown as PlanbatenOfSchadeInlichtingCompensatieType,
    oorspronkelijkeBestemming: input.oorspronkelijkeBestemming as unknown as PlanbatenOfSchadeInlichtingOorspronkelijkeBestemming,
    nieuweBestemming: input.nieuweBestemming as unknown as PlanbatenOfSchadeInlichtingNieuweBestemming,
    dossiertype: input.dossiertype,
    dossiernummer: input.dossiernummer,
    planfase: input.planfase,
    externeDocumentatie: input.externeDocumentatie
  } as PlanbatenOfSchadeInlichting
}

export default defineComponent({
  name: 'PlanbatenOfSchade',
  components: { VipInlichtingVanToepassing, VipInlichtingPreviewNew, VipInlichtingEdit },
  props: {
    inlichting: {
      type: Object as PropType<Inlichting>,
      required: false
    },
    dossierId: {
      type: String,
      required: true
    },
    dossierStatus: {
      type: Number as PropType<DossierStatus>,
      required: false
    },
    canDelete: Boolean
  },
  setup (props, { emit }) {
    const {
      refs,
      inlichtingKey,
      vipInlichting,
      inlichtingInput,
      canEdit,
      validationErrors,
      accordion,
      saving,
      toggleIndicator,
      inlichtingToggled,
      cancelEdit,
      saveInlichting,
      validationFailed
    } = useEditableInlichting(
      props.inlichting ?? ({ inlichtingType: VipInlichtingType.PLANBATEN_OF_SCHADE } as unknown as PlanbatenOfSchadeInlichting),
      mapInlichtingToInput,
      mapInputToInlichting)

    const inlichtingType = TypeInlichting.PlanbatenOfSchade
    const inlichtingTitle = computed(() => inlichtingen.find(inl => inl.inlichtingType === inlichtingType).label)

    const planfaseInput = ref((vipInlichting?.value.planfase ?? {}) as PlanbatenOfSchadePlanfase)

    const options = new Map([
      ['compensatieType',
        new Map([
          ['KAPITAALSCHADE_OF_GEBRUIKERSSCHADE', 'Kapitaalschade of gebruikersschade'],
          ['PLANBATEN', 'Planbaten'],
          ['PLANSCHADE', 'Planschade'],
          ['ZONES_ZONDER_PLANCOMPENSATIE', 'Zones zonder plancompensatie']
        ])
      ],
      ['bestemming',
        new Map([
          ['', ''],
          ['BEDRIJVIGHEID', 'Bedrijvigheid'],
          ['BOS_OVERIG_GROEN_OF_RESERVAAT_EN_NATUUR', 'Bos, overig groen of reservaat en natuur'],
          ['BUITEN_RUP', 'Buiten het RUP dat planbaten etc. genereert / niet relevant'],
          ['GEBIED_VOOR_WINNING_VAN_OPPERVLAKTEDELFSTOFFEN', 'Gebied voor winning van oppervlaktedelfstoffen'],
          ['GEMEENSCHAPS_EN_NUTSVOORZIENINGEN', 'Gemeenschaps- en nutsvoorzieningen'],
          ['LANDBOUW', 'Landbouw'],
          ['RECREATIE', 'Recreatie'],
          ['WONEN', 'Wonen']
        ])
      ],
      ['planstatus',
        new Map([
          ['', ''],
          ['START', 'Start'],
          ['PLENAIRE_VERGADERING', 'Plenaire vergadering'],
          ['SCOPING', 'Scoping'],
          ['VOORLOPIGE_VASTSTELLING', 'Voorlopige vaststelling'],
          ['DEFINITIEVE_VASTSTELLING', 'Definitieve vaststelling'],
          ['BESLUIT_TOT_GOEDKEURING', 'Besluit tot goedkeuring'],
          ['NATRAJECT', 'Natraject'],
          ['ADVISERING', 'Advisering'],
          ['DEFINITIEVE_AANDUIDING', 'Definitieve aanduiding']
        ])
      ]
    ])

    const validateInput = (): Map<string, string> => {
      const errors = new Map<string, string>()
      if (!inlichtingInput.value.compensatieType) {
        errors.set('compensatietype', 'Compensatietype is verplicht')
      }
      if (!inlichtingInput.value.beschrijving) {
        errors.set('beschrijving', 'Beschrijving is verplicht')
      }
      if (!inlichtingInput.value.referentie) {
        errors.set('referentie', 'Referentie is verplicht')
      }
      return errors
    }

    const saved = (inlichtingId: string) => {
      saveInlichting(inlichtingId)
      emit('saved', vipInlichting.value)
      inlichtingToggled(true)
    }

    const removed = () => {
      emit('removed', vipInlichting.value.inlichtingId)
      EventBus.$emit('close-modal', 'vl-modal-backdrop')
    }
    const cancelled = () => {
      cancelEdit()
      emit('cancelled')
    }

    watch(() => inlichtingInput.value.inlichtingenIndicator, (newValue) => {
      if (newValue === InlichtingenIndicator.NEE) {
        inlichtingInput.value.planfase = null
      }
    }, { immediate: true })

    watch(() => planfaseInput.value, (newValue: PlanbatenOfSchadePlanfase) => {
      if (newValue.status == null) {
        inlichtingInput.value.planfase = null
      } else {
        inlichtingInput.value.planfase = newValue
      }
    }, { immediate: true, deep: true })

    return {
      vipInlichting,
      inlichtingInput,
      validationErrors,
      saving,
      accordion,
      refs,
      inlichtingKey,
      canEdit,
      inlichtingType,
      inlichtingTitle,
      options,
      planfaseInput,
      inlichtingToggled,
      toggleIndicator,
      validateInput,
      validationFailed,
      saved,
      removed,
      cancelled,
      formatNumber,
      formatDate
    }
  }
})
