import { computed, defineComponent, PropType } from 'vue'
import VipInlichtingVanToepassing
  from '@/components/vip-inlichting/components/vip-inlichting-van-toepassing/vip-inlichting-van-toepassing.vue'
import {
  BronInformatie,
  DossierStatus,
  Inlichting,
  InlichtingenIndicator,

  InlichtingRubriek,
  StedenbouwkundigAttestInlichting,
  StedenbouwkundigAttestInlichtingAardAanvraag,
  StedenbouwkundigAttestInlichtingAardBeslissing,
  StedenbouwkundigAttestInlichtingInstantieBeslissing,
  StedenbouwkundigAttestInput,
  StedenbouwkundigAttestInputAardAanvraag,
  StedenbouwkundigAttestInputAardBeslissing,
  StedenbouwkundigAttestInputInstantieBeslissing,
  TypeInlichting,
  VipInlichtingDiscriminator,
  VipInlichtingType
} from '@/infrastructure/bff-client/bff-client'
import VipInlichtingPreviewNew
  from '@/components/vip-inlichting/new-version/components/inlichting-preview/vip-inlichting-preview-new.vue'
import { formatDate, formatNumber } from '@/infrastructure/filters/filters'
import { inlichtingen } from '@/infrastructure/constants/inlichting-types-constants'
import useEditableInlichting from '@/components/vip-inlichting/new-version/composables/editable-inlichting.composable'
import EventBus from '@/infrastructure/events/event-bus'
import VipInlichtingEdit
  from '@/components/vip-inlichting/new-version/components/inlichting-edit/vip-inlichting-edit.vue'

function mapInlichtingToInput (vipInlichting?: StedenbouwkundigAttestInlichting): StedenbouwkundigAttestInput {
  return {
    inlichtingenIndicator: vipInlichting === null ? InlichtingenIndicator.NEE : vipInlichting.inlichtingenIndicator,
    discriminator: VipInlichtingDiscriminator.STEDENBOUWKUNDIG_ATTEST_V1,
    inlichtingType: VipInlichtingType.STEDENBOUWKUNDIG_ATTEST,
    beschrijving: vipInlichting?.beschrijving,
    gemeentelijkDossiernummer: vipInlichting?.gemeentelijkDossiernummer,
    referentie: vipInlichting?.referentie,
    aardBeslissing: vipInlichting?.aardBeslissing as unknown as StedenbouwkundigAttestInputAardBeslissing,
    datumBeslissing: vipInlichting?.datumBeslissing,
    aardAanvraag: vipInlichting?.aardAanvraag as unknown as StedenbouwkundigAttestInputAardAanvraag,
    instantieBeslissing: vipInlichting?.instantieBeslissing as unknown as StedenbouwkundigAttestInputInstantieBeslissing,
    externeDocumentatie: vipInlichting?.externeDocumentatie
  } as StedenbouwkundigAttestInput
}

function mapInputToInlichting (input: StedenbouwkundigAttestInput, inlichtingId: string): StedenbouwkundigAttestInlichting {
  return {
    inlichtingId,
    inlichtingenIndicator: input.inlichtingenIndicator as unknown as InlichtingenIndicator,
    inlichtingType: VipInlichtingType.STEDENBOUWKUNDIG_ATTEST,
    version: 1,
    bronInformatie: {
      informatieverstrekker: 'Lokaal bestuur',
      laatsteWijzigingTijdstip: new Date()
    } as BronInformatie,
    kaarten: [],
    rubriek: InlichtingRubriek.OVERHEIDSPLANNEN,
    beschrijving: input.beschrijving,
    gemeentelijkDossiernummer: input.gemeentelijkDossiernummer,
    referentie: input.referentie,
    aardBeslissing: input.aardBeslissing as unknown as StedenbouwkundigAttestInlichtingAardBeslissing,
    datumBeslissing: input.datumBeslissing,
    aardAanvraag: input.aardAanvraag as unknown as StedenbouwkundigAttestInlichtingAardAanvraag,
    instantieBeslissing: input.instantieBeslissing as unknown as StedenbouwkundigAttestInlichtingInstantieBeslissing,
    externeDocumentatie: input.externeDocumentatie
  } as StedenbouwkundigAttestInlichting
}

export default defineComponent({
  name: 'StedenbouwkundigAttest',
  components: { VipInlichtingVanToepassing, VipInlichtingPreviewNew, VipInlichtingEdit },
  props: {
    inlichting: {
      type: Object as PropType<Inlichting>,
      required: false
    },
    dossierId: {
      type: String,
      required: true
    },
    dossierStatus: {
      type: Number as PropType<DossierStatus>,
      required: false
    },
    canDelete: Boolean
  },
  setup (props, { emit }) {
    const {
      refs,
      inlichtingKey,
      vipInlichting,
      inlichtingInput,
      canEdit,
      validationErrors,
      accordion,
      saving,
      addedBijlagen,
      isNietGekend,
      bijlageUploaded,
      toggleIndicator,
      inlichtingToggled,
      cancelEdit,
      saveInlichting,
      validationFailed,
      downloadUrl,
      bijlagenSaved,
      bijlageDeleted
    } = useEditableInlichting(
      props.inlichting ?? ({ inlichtingType: VipInlichtingType.STEDENBOUWKUNDIG_ATTEST } as unknown as StedenbouwkundigAttestInlichting),
      mapInlichtingToInput,
      mapInputToInlichting)

    const inlichtingType = TypeInlichting.StedenbouwkundigAttest
    const inlichtingTitle = computed(() => inlichtingen.find(inl => inl.inlichtingType === inlichtingType).label)

    const optionsBeslissing = new Map([
      ['POSITIEF', 'Positief'],
      ['NEGATIEF', 'Negatief'],
      ['ANDERE', 'Andere beslissing']
    ])

    const optionsAard = new Map([
      ['GROND_OPSLAG', 'Gronden gebruiken, aanleggen of inrichten voor de opslag van gebruikte of afgedankte voertuigen, van allerhande materialen, materieel of afval'],
      ['GROND_PARKEREN', 'Gronden gebruiken, aanleggen of inrichten voor parkeren van voertuigen, wagens of aanhangwagens'],
      ['GROND_PLAATSEN_PUBLICITEIT', 'Gronden gebruiken, aanleggen of inrichten voor plaatsen van 1 of meer verplaatsbare inrichtingen of rollend materieel die hoofdzakelijk publicitaire doeleinden worden gebruikt'],
      ['NIET_GEKEND', 'Niet gekend'],
      ['NIEUW_BIJGEBOUW', 'Nieuwbouw bijgebouw'],
      ['NIEUW_EENGEZINSWONING', 'Nieuwbouw eengezinswoning'],
      ['NIEUW_HANDEL', 'Nieuwbouw handel, horeca, diensten (bvb banken, winkels, winkelcentra, …)'],
      ['NIEUW_INDUSTRIE', 'Nieuwbouw industrie, ambacht'],
      ['NIEUW_INFRASTRUCTUUR', 'Nieuwbouw infrastructuren'],
      ['NIEUW_KANTOREN', 'Nieuwbouw kantoren'],
      ['NIEUW_LAND_TUINBOUW', 'Nieuwbouw land- en tuinbouw'],
      ['NIEUW_MEERGEZINSWONING', 'Nieuwbouw meergezinswoning'],
      ['NIEUW_OPENBAAR_NUT', 'Nieuwbouw gemeenschapsvoorziening of openbaar nut (scholen, religieuze gebouwen, ziekenhuizen, rusthuizen, …)'],
      ['NIEUW_TOERISME', 'Nieuwbouw toerisme en recreatie'],
      ['ONTBOSSEN', 'Ontbossen'],
      ['PUBLICITEITSINRICHTINGEN', 'Publiciteitsinrichtingen'],
      ['RELIEFWIJZIGING', 'Reliëfwijziging'],
      ['SLOPEN_BIJGEBOUW', 'Slopen bijgebouw", "Slopen bijgebouw'],
      ['SLOPEN_EENGEZINSWONING', 'Slopen eengezinswoning", "Slopen eengezinswoning'],
      ['SLOPEN_HANDEL', 'Slopen handel, horeca'],
      ['SLOPEN_INDUSTRIE', 'Slopen industrie, ambacht'],
      ['SLOPEN_INFRASTRUCTUUR', 'Slopen infrastructuren'],
      ['SLOPEN_KANTOREN', 'Slopen kantoren", "Slopen kantoren'],
      ['SLOPEN_LAND_TUINBOUW', 'Slopen land- en tuinbouw'],
      ['SLOPEN_MEERGEZINSWONING', 'Slopen meergezinswoning", "Slopen meergezinswoning'],
      ['SLOPEN_OPENBAAR_NUT', 'Slopen gemeenschapsvoorziening of openbaar nut'],
      ['SLOPEN_TOERISME', 'Slopen toerisme en recreatie'],
      ['VELLEN_BOMEN', 'Vellen hoogstammige bomen'],
      ['VERBOUW_BIJGEBOUW', 'Verbouwen van/tot of uitbreiden van bijgebouw'],
      ['VERBOUW_EENGEZINSWONING', 'Verbouwen van/tot of uitbreiden van eengezinswoning'],
      ['VERBOUW_HANDEL', 'Verbouwen van/tot of uitbreiden van handel, horeca, diensten'],
      ['VERBOUW_INDUSTRIE', 'Verbouwen van/tot of uitbreiden van industrie, ambacht'],
      ['VERBOUW_INFRASTRUCTUUR', 'Verbouwen van/tot of uitbreiden van infrastructuren'],
      ['VERBOUW_KANTOREN', 'Verbouwen van/tot of uitbreiden van kantoren'],
      ['VERBOUW_LAND_TUINBOUW', 'Verbouwen van/tot of uitbreiden van land - en tuinbouw'],
      ['VERBOUW_MEERGEZINSWONING', 'Verbouwen van/tot of uitbreiden van meergezinswoning'],
      ['VERBOUW_OPENBAAR_NUT', 'Verbouwen van/tot of uitbreiden van gemeenschapsvoorziening of openbaar nut'],
      ['VERBOUW_TOERISME', 'Verbouwen van/tot of uitbreiden van toerisme en recreatie'],
      ['VERBOUWEN_ZONDER_FUNCTIEWIJZIGING', 'Verbouwen zonder functiewijziging en met wijziging van het aantal woongelegenheden'],
      ['WIJZIG_AANTAL_WOONEENHEDEN', 'Wijzigen aantal woongelegenheden bestemd voor de huisvesting van een gezin of een alleenstaande']
    ])

    const optionsInstantie = new Map([
      ['GEDELEGEERD_STEDENBOUWKUNDIG_AMBTENAAR', 'Gedelegeerd stedenbouwkundig ambtenaar'],
      ['GEMACHTIGDE_AMBTENAAR', 'Gemachtigde ambtenaar'],
      ['GEMEENTE', 'Gemeente'],
      ['GEWESTELIJK_STEDENBOUWKUNDIG_AMBTENAAR', 'Gewestelijk stedenbouwkundig ambtenaar'],
      ['PROVINCIE', 'Provincie'],
      ['VLAAMSE_GEWEST', 'Vlaams gewest']
    ])

    const validateInput = (): Map<string, string> => {
      const errors = new Map<string, string>()
      if (!inlichtingInput.value.beschrijving) {
        errors.set('beschrijving', 'Beschrijving is verplicht')
      }
      if (!inlichtingInput.value.instantieBeslissing) {
        errors.set('instantie', 'Instantie is verplicht')
      }
      if (!inlichtingInput.value.aardAanvraag) {
        errors.set('aard', 'Aard van de aanvraag is verplicht')
      }
      if (!inlichtingInput.value.aardBeslissing) {
        errors.set('beslissing', 'Beslissing is verplicht')
      }
      if (!inlichtingInput.value.datumBeslissing) {
        errors.set('datum', 'Beslist op is verplicht')
      }
      return errors
    }

    const saved = (inlichtingId: string) => {
      saveInlichting(inlichtingId)
      emit('saved', vipInlichting.value)
      inlichtingToggled(true)
    }

    const removed = () => {
      emit('removed', vipInlichting.value.inlichtingId)
      EventBus.$emit('close-modal', 'vl-modal-backdrop')
    }

    const cancelled = () => {
      cancelEdit()
      emit('cancelled')
    }

    return {
      vipInlichting,
      inlichtingInput,
      validationErrors,
      saving,
      accordion,
      refs,
      inlichtingKey,
      canEdit,
      addedBijlagen,
      inlichtingType,
      inlichtingTitle,
      optionsBeslissing,
      optionsAard,
      optionsInstantie,
      isNietGekend,
      bijlageUploaded,
      inlichtingToggled,
      toggleIndicator,
      validateInput,
      validationFailed,
      saved,
      removed,
      cancelled,
      downloadUrl,
      bijlagenSaved,
      bijlageDeleted,
      formatNumber,
      formatDate
    }
  }
})
