import { computed, defineComponent, PropType } from 'vue'
import VipInlichtingVanToepassing
  from '@/components/vip-inlichting/components/vip-inlichting-van-toepassing/vip-inlichting-van-toepassing.vue'
import {
  PlanologischAttestInlichting,
  PlanologischAttestInlichtingAardBeslissing,
  PlanologischAttestInput,
  PlanologischAttestInputAardBeslissing,
  BronInformatie,
  DossierStatus,
  Inlichting,
  InlichtingenIndicator,
  InlichtingRubriek,
  TypeInlichting,
  VipInlichtingDiscriminator,
  VipInlichtingType, PlanologischAttestInputAardAdvies, PlanologischAttestInlichtingAardAdvies
} from '@/infrastructure/bff-client/bff-client'
import VipInlichtingPreviewNew
  from '@/components/vip-inlichting/new-version/components/inlichting-preview/vip-inlichting-preview-new.vue'
import { formatDate, formatNumber } from '@/infrastructure/filters/filters'
import { inlichtingen } from '@/infrastructure/constants/inlichting-types-constants'
import useEditableInlichting from '@/components/vip-inlichting/new-version/composables/editable-inlichting.composable'
import EventBus from '@/infrastructure/events/event-bus'
import VipInlichtingEdit
  from '@/components/vip-inlichting/new-version/components/inlichting-edit/vip-inlichting-edit.vue'

function mapInlichtingToInput (vipInlichting?: PlanologischAttestInlichting): PlanologischAttestInput {
  return {
    inlichtingenIndicator: vipInlichting === null ? InlichtingenIndicator.NEE : vipInlichting.inlichtingenIndicator,
    discriminator: VipInlichtingDiscriminator.PLANOLOGISCH_ATTEST_V1,
    inlichtingType: VipInlichtingType.PLANOLOGISCH_ATTEST,
    beschrijving: vipInlichting?.beschrijving,
    dossiernummer: vipInlichting?.dossiernummer,
    referentie: vipInlichting?.referentie,
    aardBeslissing: vipInlichting?.aardBeslissing as unknown as PlanologischAttestInputAardBeslissing,
    datumBeslissing: vipInlichting?.datumBeslissing,
    aardAdvies: vipInlichting?.aardAdvies as unknown as PlanologischAttestInputAardAdvies,
    datumAdvies: vipInlichting?.datumAdvies,
    externeDocumentatie: vipInlichting?.externeDocumentatie
  } as PlanologischAttestInput
}

function mapInputToInlichting (input: PlanologischAttestInput, inlichtingId: string): PlanologischAttestInlichting {
  return {
    inlichtingId,
    inlichtingenIndicator: input.inlichtingenIndicator as unknown as InlichtingenIndicator,
    inlichtingType: VipInlichtingType.PLANOLOGISCH_ATTEST,
    version: 1,
    bronInformatie: {
      informatieverstrekker: 'Lokaal bestuur',
      laatsteWijzigingTijdstip: new Date()
    } as BronInformatie,
    kaarten: [],
    rubriek: InlichtingRubriek.OVERHEIDSPLANNEN,
    beschrijving: input.beschrijving,
    dossiernummer: input.dossiernummer,
    referentie: input.referentie,
    aardBeslissing: input.aardBeslissing as unknown as PlanologischAttestInlichtingAardBeslissing,
    datumBeslissing: input.datumBeslissing,
    aardAdvies: input.aardAdvies as unknown as PlanologischAttestInlichtingAardAdvies,
    datumAdvies: input.datumAdvies,
    externeDocumentatie: input.externeDocumentatie
  } as PlanologischAttestInlichting
}

export default defineComponent({
  name: 'PlanologischAttest',
  components: { VipInlichtingVanToepassing, VipInlichtingPreviewNew, VipInlichtingEdit },
  props: {
    inlichting: {
      type: Object as PropType<Inlichting>,
      required: false
    },
    dossierId: {
      type: String,
      required: true
    },
    dossierStatus: {
      type: Number as PropType<DossierStatus>,
      required: false
    },
    canDelete: Boolean
  },
  setup (props, { emit }) {
    const {
      refs,
      inlichtingKey,
      vipInlichting,
      inlichtingInput,
      canEdit,
      validationErrors,
      accordion,
      saving,
      addedBijlagen,
      bijlageUploaded,
      toggleIndicator,
      inlichtingToggled,
      cancelEdit,
      saveInlichting,
      validationFailed,
      downloadUrl,
      bijlagenSaved,
      bijlageDeleted
    } = useEditableInlichting(
      props.inlichting ?? ({ inlichtingType: VipInlichtingType.PLANOLOGISCH_ATTEST } as unknown as PlanologischAttestInlichting),
      mapInlichtingToInput,
      mapInputToInlichting)

    const inlichtingType = TypeInlichting.PlanologischAttest
    const inlichtingTitle = computed(() => inlichtingen.find(inl => inl.inlichtingType === inlichtingType).label)

    const optionsBeslissing = new Map([
      ['POSITIEF', 'Positief'],
      ['NEGATIEF', 'Negatief'],
      ['ANDERE', 'Andere']
    ])

    const optionsAdvies = new Map([
      ['GUNSTIG', 'Gunstig'],
      ['ONGUNSTIG', 'Ongunstig']
    ])

    const validateInput = (): Map<string, string> => {
      const errors = new Map<string, string>()
      if (!inlichtingInput.value.beschrijving) {
        errors.set('beschrijving', 'Beschrijving is verplicht')
      }
      if (!inlichtingInput.value.referentie) {
        errors.set('register', 'Registernummer is verplicht')
      }
      if (!inlichtingInput.value.aardBeslissing) {
        errors.set('beslissing', 'Beslissing is verplicht')
      }
      if (!inlichtingInput.value.datumBeslissing) {
        errors.set('datum', 'Beslist op is verplicht')
      }
      return errors
    }

    const saved = (inlichtingId: string) => {
      saveInlichting(inlichtingId)
      emit('saved', vipInlichting.value)
      inlichtingToggled(true)
    }

    const removed = () => {
      emit('removed', vipInlichting.value.inlichtingId)
      EventBus.$emit('close-modal', 'vl-modal-backdrop')
    }

    const cancelled = () => {
      cancelEdit()
      emit('cancelled')
    }

    return {
      vipInlichting,
      inlichtingInput,
      validationErrors,
      saving,
      accordion,
      refs,
      inlichtingKey,
      canEdit,
      addedBijlagen,
      inlichtingType,
      inlichtingTitle,
      optionsBeslissing,
      optionsAdvies,
      bijlageUploaded,
      inlichtingToggled,
      toggleIndicator,
      validateInput,
      validationFailed,
      saved,
      removed,
      cancelled,
      downloadUrl,
      bijlagenSaved,
      bijlageDeleted,
      formatNumber,
      formatDate
    }
  }
})
