import { computed, defineComponent, PropType, watch } from 'vue'
import {
  BronInformatie,
  DossierStatus,
  InlichtingenIndicator,

  InlichtingRubriek,
  MilieuBeslissingEersteAanleg,
  MilieuvergunningInlichting,
  MilieuvergunningInput,
  TypeInlichting,
  VipInlichtingDiscriminator,
  VipInlichtingType
} from '@/infrastructure/bff-client/bff-client'
import useEditableInlichting from '@/components/vip-inlichting/new-version/composables/editable-inlichting.composable'
import VipInlichtingVanToepassing
  from '@/components/vip-inlichting/components/vip-inlichting-van-toepassing/vip-inlichting-van-toepassing.vue'
import { formatDate, formatNumber } from '@/infrastructure/filters/filters'
import EventBus from '@/infrastructure/events/event-bus'
import { inlichtingen } from '@/infrastructure/constants/inlichting-types-constants'
import useStedenbouwkundigeOvertredingLabels
  from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/stedenbouwkundige-overtreding/composable/stedenbouwkundige-overtreding-labels.composable'
import MilieuvergunningEdit
  from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/milieuvergunning/edit/milieuvergunning-edit.vue'
import MilieuvergunningPreview
  from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/milieuvergunning/preview/milieuvergunning-preview.vue'
import VipInlichtingPreviewNew
  from '@/components/vip-inlichting/new-version/components/inlichting-preview/vip-inlichting-preview-new.vue'
import VipInlichtingEdit
  from '@/components/vip-inlichting/new-version/components/inlichting-edit/vip-inlichting-edit.vue'

function mapInlichtingToInput (vipInlichting?: MilieuvergunningInlichting): MilieuvergunningInput {
  const copy = structuredClone(vipInlichting)
  return {
    inlichtingenIndicator: copy === null ? InlichtingenIndicator.NEE : copy.inlichtingenIndicator,
    discriminator: VipInlichtingDiscriminator.MILIEUVERGUNNING_V1,
    inlichtingType: VipInlichtingType.MILIEUVERGUNNING,
    beschrijving: copy?.beschrijving,
    externeDocumentatie: copy?.externeDocumentatie,
    dossiernummer: copy?.dossiernummer,
    referentienummer: copy?.referentienummer,
    inrichtingsnummer: copy?.inrichtingsnummer,
    risicoklasse: copy?.risicoklasse,
    vergunningsStatus: copy?.vergunningsStatus,
    beslissing: copy?.beslissing ?? {} as MilieuBeslissingEersteAanleg,
    beroepsprocedures: copy?.beroepsprocedures,
    startdatum: copy?.startdatum,
    einddatum: copy?.einddatum,
    voorwaarden: copy?.voorwaarden,
    activiteiten: copy?.activiteiten,
    vergunningProcedure: copy?.vergunningProcedure,
  } as MilieuvergunningInput
}

function mapInputToInlichting (input: MilieuvergunningInput, inlichtingId: string): MilieuvergunningInlichting {
  const copy = structuredClone(input)
  return {
    inlichtingId,
    inlichtingenIndicator: copy.inlichtingenIndicator as unknown as InlichtingenIndicator,
    discriminator: VipInlichtingDiscriminator.MILIEUVERGUNNING_V1,
    inlichtingType: VipInlichtingType.MILIEUVERGUNNING,
    version: 1,
    rubriek: InlichtingRubriek.VERGUNNINGEN,
    bronInformatie: {
      informatieverstrekker: 'Lokaal bestuur',
      laatsteWijzigingTijdstip: new Date()
    } as BronInformatie,
    kaarten: [],
    beschrijving: copy.beschrijving,
    externeDocumentatie: copy.externeDocumentatie,
    dossiernummer: copy.dossiernummer,
    referentienummer: copy.referentienummer,
    inrichtingsnummer: copy.inrichtingsnummer,
    risicoklasse: copy.risicoklasse,
    vergunningsStatus: copy.vergunningsStatus,
    beslissing: copy.beslissing,
    beroepsprocedures: copy.beroepsprocedures,
    startdatum: copy.startdatum,
    einddatum: copy.einddatum,
    voorwaarden: copy.voorwaarden,
    activiteiten: copy.activiteiten,
    vergunningProcedure: copy.vergunningProcedure,
  } as MilieuvergunningInlichting
}

export default defineComponent({
  name: 'Milieuvergunning',
  components: {
    MilieuvergunningEdit,
    MilieuvergunningPreview,
    VipInlichtingPreviewNew,
    VipInlichtingEdit,
    VipInlichtingVanToepassing,
  },
  props: {
    inlichting: {
      type: Object as PropType<MilieuvergunningInlichting>,
      required: false
    },
    dossierId: {
      type: String,
      required: true
    },
    dossierStatus: {
      type: Number as PropType<DossierStatus>,
      required: false
    },
    canDelete: Boolean
  },
  setup (props, { emit }) {
    const {
      refs,
      vipInlichting,
      inlichtingInput,
      canEdit,
      validationErrors,
      addedBijlagen,
      accordion,
      saving,
      inlichtingKey,
      isNietGekend,
      bijlageUploaded,
      toggleIndicator,
      inlichtingToggled,
      cancelEdit,
      saveInlichting,
      bijlagenSaved,
      bijlageDeleted,
      validationFailed
    } = useEditableInlichting(
      props.inlichting ??
        ({ inlichtingType: VipInlichtingType.MILIEUVERGUNNING } as unknown as MilieuvergunningInlichting),
      mapInlichtingToInput,
      mapInputToInlichting
    )

    const { options, getLabel } = useStedenbouwkundigeOvertredingLabels()

    const inlichtingType = TypeInlichting.Milieuvergunning
    const inlichtingTitle = computed(() => inlichtingen.find(inl => inl.inlichtingType === inlichtingType).label)

    const validateInput = (): Map<string, string> => {
      const errors = new Map<string, any>()
      const inputToCheck = inlichtingInput.value as MilieuvergunningInput
      if (inputToCheck.inlichtingenIndicator === InlichtingenIndicator.JA) {
        if (!inputToCheck.beschrijving) {
          errors.set('beschrijving', true)
        }
        if (!inputToCheck.dossiernummer) {
          errors.set('dossiernummer', true)
        }
        if (inputToCheck.beslissing?.status == null) {
          errors.set('beslissing-status', true)
        }
        if (inputToCheck.beslissing?.datum == null && inputToCheck.beslissing?.status !== 'GEEN_BESLISSING') {
          errors.set('beslissing-datum', true)
        }
        if ((inputToCheck.beroepsprocedures ?? []).length > 0) {
          inputToCheck.beroepsprocedures.forEach((beroep: any, index) => {
            if (beroep.beroepsinstantie == null) {
              errors.set(`beroep-${index}-instantie`, true)
            }
            if (beroep.beslissing == null) {
              errors.set(`beroep-${index}-beslissing`, true)
            }
          })
        }
        if (inputToCheck.vergunningProcedure == null) {
          errors.set('vergunning-procedure', true)
        }
        if ((inputToCheck.activiteiten ?? []).length > 0) {
          inputToCheck.activiteiten.forEach((activiteit, index) => {
            if (!activiteit.rubriek) {
              errors.set(`activiteit-${index}-rubrieknummer`, true)
            }
            if (activiteit.risicoklasse == null) {
              errors.set(`activiteit-${index}-risicoklasse`, true)
            }
            if (activiteit.status == null) {
              errors.set(`activiteit-${index}-status`, true)
            }
          })
        }
      }
      return errors
    }

    const inputUpdated = (input: MilieuvergunningInput) => {
      inlichtingInput.value = input
    }

    const saved = (inlichtingId: string) => {
      saveInlichting(inlichtingId)
      emit('saved', vipInlichting.value)
      inlichtingToggled(true)
    }

    const removed = () => {
      emit('removed', vipInlichting.value.inlichtingId)
      EventBus.$emit('close-modal', 'vl-modal-backdrop')
    }

    const cancelled = () => {
      cancelEdit()
      emit('cancelled')
    }

    watch(() => inlichtingInput.value.inlichtingenIndicator, (newValue) => {
      if (newValue === InlichtingenIndicator.NEE) {
        inlichtingInput.value.beslissing = null
      } else if (newValue === InlichtingenIndicator.JA) {
        inlichtingInput.value.beslissing = inlichtingInput.value.value ?? {} as MilieuBeslissingEersteAanleg
      }
    })

    return {
      vipInlichting,
      inlichtingInput,
      validationErrors,
      saving,
      accordion,
      refs,
      inlichtingKey,
      canEdit,
      addedBijlagen,
      isNietGekend,
      inlichtingType,
      inlichtingTitle,
      options,
      bijlageUploaded,
      inlichtingToggled,
      toggleIndicator,
      validateInput,
      validationFailed,
      saved,
      removed,
      cancelled,
      formatNumber,
      getLabel,
      formatDate,
      inputUpdated,
      bijlagenSaved,
      bijlageDeleted
    }
  }
})
