import {
  ArrestVanRaadVoorVergunningbetwisting,
  BeslissingInEersteAdministratieveAanleg,
  BeslissingInTweedeAdministratieveAanleg,
  BeslissingOverDeMelding,
  OmgevingsvergunningBeslissing,
  VergunningBeslissingType, OmgevingsvergunningInput,
} from '@/infrastructure/bff-client/bff-client'
import { computed, defineComponent, onBeforeMount, PropType, ref } from 'vue'
import EventBus from '@/infrastructure/events/event-bus'
import useEditHelper from '../../composables/edit-helper'
import ContentCollection
  from '@/components/vip-inlichting/new-version/components/content-collection/content-collection.vue'
import MeldingEdit
  from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/omgevingsvergunning/edit/beslissingen/melding-edit.vue'
import TweedeAdministratieveAanlegEdit
  from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/omgevingsvergunning/edit/beslissingen/tweede-administratieve-aanleg-edit.vue'
import VergunningsBetwistingEdit
  from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/omgevingsvergunning/edit/beslissingen/vergunnings-betwisting-edit.vue'
import RaadVanStateEdit
  from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/omgevingsvergunning/edit/beslissingen/raad-van-state-edit.vue'
import EersteAdministratieveAanlegEdit
  from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/omgevingsvergunning/edit/beslissingen/eerste-administratieve-aanleg-edit.vue'

export enum BeslissingType {
      melding = 'melding',
      eersteAdministratieveAanleg = 'eerste-administratieve-aanleg',
      tweedeAdministratieveAanleg = 'tweede-administratieve-aanleg',
      vergunningsBetwisting = 'vergunnings-betwisting',
      raadVanState = 'raad-van-state'
  }

export interface Beslissing {
      type: BeslissingType,
      element?: BeslissingOverDeMelding | BeslissingInEersteAdministratieveAanleg | BeslissingInTweedeAdministratieveAanleg | ArrestVanRaadVoorVergunningbetwisting | ArrestVanRaadVoorVergunningbetwisting,
      index?: number
    }

export default defineComponent({
  name: 'beslissingen-edit',
  components: {
    ContentCollection,
    MeldingEdit,
    EersteAdministratieveAanlegEdit,
    TweedeAdministratieveAanlegEdit,
    VergunningsBetwistingEdit,
    RaadVanStateEdit
  },
  props: {
    input: Object as PropType<OmgevingsvergunningInput>,
    modDisabled: Boolean,
    validationErrors: Map<string, string>
  },
  setup (props) {
    const loaded = ref(false)
    const inlichtingInput = ref<OmgevingsvergunningInput>(props.input)
    const indexToRemove = ref(null)
    const beslissingsItems = ref([])
    const beslissingsItemToevoegenActief = ref(false)
    const activeBeslissingsItem = ref(null)
    const { makeUnique } = useEditHelper()

    const addBeslissing = () => {
      beslissingsItemToevoegenActief.value = true
    }
    const beslissingsOptions = computed(() => {
      const beslissingen = [['Beslissing over de melding', 'melding'],
        ['Beslissing in eerste administratieve aanleg', 'eerste-administratieve-aanleg'],
        ['Beslissing in tweede administratieve aanleg', 'tweede-administratieve-aanleg'],
        ['Arrest van Raad voor vergunningbetwisting', 'vergunnings-betwisting'],
        ['Arrest van Raad van State', 'raad-van-state']]
      return beslissingen
    })

    const getComponentName = (type: VergunningBeslissingType): string => {
      switch (type) {
        case VergunningBeslissingType.BESLISSING_OVER_DE_MELDING:
          return 'melding'
        case VergunningBeslissingType.BESLISSING_IN_EERSTE_ADMINISTRATIEVE_AANLEG:
          return 'eerste-administratieve-aanleg'
        case VergunningBeslissingType.BESLISSING_IN_TWEEDE_ADMINISTRATIEVE_AANLEG:
          return 'tweede-administratieve-aanleg'
        case VergunningBeslissingType.ARREST_VAN_RAAD_VOOR_VERGUNNINGBETWISTING:
          return 'vergunnings-betwisting'
        case VergunningBeslissingType.ARREST_VAN_RAAD_VAN_STATE:
          return 'raad-van-state'
      }
    }

    const flattenInstruments = (): Beslissing[] => {
      const beslissingsElementen = []
      inlichtingInput.value?.beslissingen?.forEach((beslissing, index) => {
        beslissingsElementen.push({ type: getComponentName((beslissing as any).beslissingType), element: beslissing, index })
      })
      return beslissingsElementen
    }

    const beslissingSelectieToevoegen = () => {
      switch (activeBeslissingsItem.value) {
        case 'melding':
          if (inlichtingInput.value.beslissingen == null) { inlichtingInput.value.beslissingen = [] }
          inlichtingInput.value.beslissingen.push({ beslissingType: VergunningBeslissingType.BESLISSING_OVER_DE_MELDING } as OmgevingsvergunningBeslissing)
          break
        case 'eerste-administratieve-aanleg':
          if (inlichtingInput.value.beslissingen == null) { inlichtingInput.value.beslissingen = [] }
          inlichtingInput.value.beslissingen.push({ beslissingType: VergunningBeslissingType.BESLISSING_IN_EERSTE_ADMINISTRATIEVE_AANLEG } as OmgevingsvergunningBeslissing)
          break
        case 'tweede-administratieve-aanleg':
          if (inlichtingInput.value.beslissingen == null) { inlichtingInput.value.beslissingen = [] }
          inlichtingInput.value.beslissingen.push({ beslissingType: VergunningBeslissingType.BESLISSING_IN_TWEEDE_ADMINISTRATIEVE_AANLEG } as OmgevingsvergunningBeslissing)
          break
        case 'vergunnings-betwisting':
          if (inlichtingInput.value.beslissingen == null) { inlichtingInput.value.beslissingen = [] }
          inlichtingInput.value.beslissingen.push({ beslissingType: VergunningBeslissingType.ARREST_VAN_RAAD_VOOR_VERGUNNINGBETWISTING } as OmgevingsvergunningBeslissing)
          break
        case 'raad-van-state':
          if (inlichtingInput.value.beslissingen == null) { inlichtingInput.value.beslissingen = [] }
          inlichtingInput.value.beslissingen.push({ beslissingType: VergunningBeslissingType.ARREST_VAN_RAAD_VAN_STATE } as OmgevingsvergunningBeslissing)
          break
        default:
          break
      }
      beslissingsItems.value = flattenInstruments()
      beslissingsItemToevoegenActief.value = false
    }

    const beslissingToevoegenAnnuleren = () => {
      beslissingsItemToevoegenActief.value = false
    }

    const cancelRemove = () => {
      indexToRemove.value = null
    }

    const handleBeslissingChange = (beslissingToChange: { index: number, type: BeslissingType, value: any}) => {
      const copy = structuredClone(inlichtingInput.value)
      copy.beslissingen[beslissingToChange.index] = beslissingToChange.value
      inlichtingInput.value = copy
      beslissingsItems.value = flattenInstruments()
    }

    const removeBeslissing = (indexToRemove: number) => {
      const copy = structuredClone(inlichtingInput.value)
      copy.beslissingen.splice(indexToRemove, 1)
      inlichtingInput.value = copy
      beslissingsItems.value = flattenInstruments()
      EventBus.$emit('close-modal', 'vl-modal-backdrop')
    }

    onBeforeMount(() => {
      beslissingsItems.value = flattenInstruments()
    })

    return {
      beslissingsItems,
      beslissingsItemToevoegenActief,
      beslissingsOptions,
      activeBeslissingsItem,
      indexToRemove,
      loaded,
      beslissingSelectieToevoegen,
      handleBeslissingChange,
      removeBeslissing,
      beslissingToevoegenAnnuleren,
      addBeslissing,
      flattenInstruments,
      makeUnique,
      cancelRemove,
    }
  }
})
