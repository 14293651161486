import { defineStore } from 'pinia'
import { IRouteConfig } from '../route/vip-router'
export enum AlertIcons {
  InfoCircle = 'info-circle',
  AlertCircle = 'alert-circle',
  Warning = 'warning',
  AlertCheck = 'check-circle'
}

export enum AlertType {
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
  Info = 'info'
}

export enum AlertMethod {
  Toast = 'toast',
  Page = 'inpage'
}

export interface IAlert {
  id: string
  closable?: boolean
  title?: string
  icon?: AlertIcons
  message?: string
  type?: AlertType
  method?: AlertMethod,
  routes?: IRouteConfig[]
}

export class Alert implements IAlert {
  id: string
  closable?: boolean
  title?: string
  icon?: AlertIcons
  message?: string
  type?: AlertType
  method?: AlertMethod
  routes?: IRouteConfig[]
  static closableError (title: string, message: string, route: IRouteConfig): Alert {
    return {
      id: 'default' + Alert.randomKey(5),
      closable: true,
      method: AlertMethod.Page,
      title,
      message,
      type: AlertType.Error,
      routes: [route]
    }
  }

  static closableSuccess (title: string, message: string, route: IRouteConfig): Alert {
    return {
      id: 'default' + Alert.randomKey(5),
      closable: true,
      method: AlertMethod.Page,
      title,
      message,
      type: AlertType.Success,
      routes: [route]
    }
  }

  private static randomKey (chars: number): string {
    let result = ''

    for (let i = 0; i < chars; i++) {
      const randomCharCode = Math.floor(Math.random() * (127 - 33) + 33) // ASCII characters between 33 and 126
      result += String.fromCharCode(randomCharCode)
    }

    return result
  }
}

export interface IAlertState{
  activeAlerts: IAlert[],
  documentationClosed: boolean
}

export const useAlertStore = defineStore('alert', {
  state: (): IAlertState => ({
    activeAlerts: [] as IAlert[],
    documentationClosed: false
  }),
  getters: {
    alertsAndToasters: (state) => {
      return state.activeAlerts
    },
    alerts: (state) => {
      return (method?: AlertMethod) => {
        return method ? state.activeAlerts.filter(alert => alert.method === method) : state.activeAlerts
      }
    },
  },
  actions: {
    addAlert (alert: IAlert) {
      this.activeAlerts.push(alert)
      if (alert.method === AlertMethod.Page) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      }
      if (alert.method === AlertMethod.Toast) {
        setTimeout(() => {
          this.activeAlerts = this.activeAlerts.filter(stateAlert => stateAlert.id !== alert.id)
        }, 5000)
      }
    },
    removeAlerts (alertIds?: string[]) {
      this.activeAlerts = alertIds ? this.activeAlerts.filter(alert => !alertIds.includes(alert.id)) : []
    },
  }
})
