import { VergunningBeslissingType, OmgevingsvergunningInlichting } from '@/infrastructure/bff-client/bff-client'
import { formatDate, formatNumber } from '@/infrastructure/filters/filters'
import { defineComponent, PropType, readonly } from 'vue'
import useOmgevingVergunningLabels from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/omgevingsvergunning/composables/omgevingsvergunning-labels'
import useInlichtingPreview from '@/components/vip-inlichting/new-version/composables/inlichting-preview.composable'
import StedenbouwkundigeHandelingPreview from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/omgevingsvergunning/preview/project-element/stedenbouwkundige-handeling-preview.vue'
import KleinhandelsActiviteitenPreview from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/omgevingsvergunning/preview/project-element/kleinhandels-activiteiten-preview.vue'
import VerkavelingVanGrondenPreview from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/omgevingsvergunning/preview/project-element/verkaveling-van-gronden-preview.vue'
import WijzigingOmgevingPreview from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/omgevingsvergunning/preview/project-element/wijziging-omgeving-preview.vue'
import ExploitatiePreview
  from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/omgevingsvergunning/preview/project-element/exploitatie-preview.vue'

export interface OmgevingsvergunningInlichtingBeslissing {
      datumBeroepIngediend?: Date | undefined;
      datumBeslissing?: Date | undefined;
      einddatumBeslissing?: Date | undefined;
      datumArrest?: Date | undefined;
      instantie?: string | undefined;
      type?: string | undefined;
      beslissingType?: VergunningBeslissingType
}

export default defineComponent({
  name: 'omgevingsvergunning-preview',
  components: {
    StedenbouwkundigeHandelingPreview,
    KleinhandelsActiviteitenPreview,
    VerkavelingVanGrondenPreview,
    ExploitatiePreview,
    WijzigingOmgevingPreview,
  },
  props: {
    inlichting: Object as PropType<OmgevingsvergunningInlichting>
  },
  setup (props) {
    const { options, getLabel } = useOmgevingVergunningLabels()
    const { downloadUrl } = useInlichtingPreview()
    const inlichtingTitle = 'Omgevingsvergunning'
    const omgevingsvergunningInlichting = readonly(props.inlichting as OmgevingsvergunningInlichting)
    const beslissingen = omgevingsvergunningInlichting.beslissingen as OmgevingsvergunningInlichtingBeslissing[]
    const beslissingsType = beslissingen?.map(beslissing => beslissing.beslissingType)

    return {
      omgevingsvergunningInlichting,
      beslissingen,
      beslissingsType,
      options,
      inlichtingTitle,
      getLabel,
      downloadUrl,
      formatDate,
      formatNumber,
    }
  }
})
