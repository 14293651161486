import { VueConstructor } from 'vue/types/umd'
import dayjs from 'dayjs'

export const formatNumber = (num: number): string => parseFloat(num.toString()).toFixed(2)
export const formatPercentage = (num: number): string => parseFloat(num.toString()).toFixed(0)

export const formatDate = (date: any): string => {
  if (date) {
    return dayjs(date).format('DD/MM/YYYY')
  } else {
    return ''
  }
}

export const formatDateTime = (date: any): string => {
  if (date) {
    return dayjs(date).format('DD/MM/YYYY HH:mm')
  } else {
    return ''
  }
}

export const formatTime = (date: any): string => {
  if (date) {
    return dayjs(date).format('HH:mm')
  } else {
    return ''
  }
}

export const formatCurrency = (value: any): string => {
  if (typeof value !== 'number') {
    return value
  }

  return '€ ' + value.toFixed(2).replace('.', ',')
}

export default {
  install (Vue: VueConstructor): void {
    Vue.filter('formatDate', function (date: any) {
      return formatDate(date)
    })
    Vue.filter('formatDateTime', function (date: any) {
      return formatDateTime(date)
    })
    Vue.filter('formatTime', function (date: any) {
      return formatTime(date)
    })
    Vue.filter('formatNumber', function (num: number) {
      return formatNumber(num)
    })
    Vue.filter('formatCurrency', function (value: any) {
      return formatCurrency(value)
    })
  }
}
