import { computed, defineComponent, PropType } from 'vue'
import VipInlichtingVanToepassing
  from '@/components/vip-inlichting/components/vip-inlichting-van-toepassing/vip-inlichting-van-toepassing.vue'
import {
  BronInformatie,
  DossierStatus,
  Inlichting,
  InlichtingenIndicator,
  InlichtingRubriek,
  SoortWegWaarlangsPerceelGelegenIsInlichtingV2,
  SoortWegWaarlangsPerceelGelegenIsInlichtingV2BeherendeInstantie,
  SoortWegWaarlangsPerceelGelegenIsInlichtingV2Toegankelijkheid,
  SoortWegWaarlangsPerceelGelegenIsInlichtingV2WegCategorie,
  SoortWegWaarlangsPerceelGelegenIsInlichtingV2WegKlasse,
  SoortWegWaarlangsPerceelGelegenIsInputV2,
  SoortWegWaarlangsPerceelGelegenIsInputV2BeherendeInstantie,
  SoortWegWaarlangsPerceelGelegenIsInputV2Toegankelijkheid,
  SoortWegWaarlangsPerceelGelegenIsInputV2WegCategorie,
  SoortWegWaarlangsPerceelGelegenIsInputV2WegKlasse,
  TypeInlichting,
  VipInlichtingDiscriminator,
  VipInlichtingType
} from '@/infrastructure/bff-client/bff-client'
import VipInlichtingPreviewNew
  from '@/components/vip-inlichting/new-version/components/inlichting-preview/vip-inlichting-preview-new.vue'
import { formatDate, formatNumber } from '@/infrastructure/filters/filters'
import { inlichtingen } from '@/infrastructure/constants/inlichting-types-constants'
import useEditableInlichting from '@/components/vip-inlichting/new-version/composables/editable-inlichting.composable'
import EventBus from '@/infrastructure/events/event-bus'
import VipInlichtingEdit
  from '@/components/vip-inlichting/new-version/components/inlichting-edit/vip-inlichting-edit.vue'

function mapInlichtingToInput (vipInlichting?: SoortWegWaarlangsPerceelGelegenIsInlichtingV2): SoortWegWaarlangsPerceelGelegenIsInputV2 {
  return {
    inlichtingenIndicator: vipInlichting === null ? InlichtingenIndicator.NEE : vipInlichting.inlichtingenIndicator,
    discriminator: VipInlichtingDiscriminator.SOORT_WEG_WAARLANGS_PERCEEL_GELEGEN_IS_V2,
    inlichtingType: VipInlichtingType.SOORT_WEG_WAARLANGS_PERCEEL_GELEGEN_IS,
    straatNaam: vipInlichting?.straatNaam,
    beherendeInstantie: vipInlichting?.beherendeInstantie as unknown as SoortWegWaarlangsPerceelGelegenIsInputV2BeherendeInstantie,
    wegKlasse: vipInlichting?.wegKlasse as unknown as SoortWegWaarlangsPerceelGelegenIsInputV2WegKlasse,
    wegCategorie: vipInlichting?.wegCategorie as unknown as SoortWegWaarlangsPerceelGelegenIsInputV2WegCategorie,
    toegankelijkheid: vipInlichting?.toegankelijkheid as unknown as SoortWegWaarlangsPerceelGelegenIsInputV2Toegankelijkheid
  } as SoortWegWaarlangsPerceelGelegenIsInputV2
}

function mapInputToInlichting (input: SoortWegWaarlangsPerceelGelegenIsInputV2, inlichtingId: string): SoortWegWaarlangsPerceelGelegenIsInlichtingV2 {
  return {
    inlichtingId,
    inlichtingenIndicator: input.inlichtingenIndicator as unknown as InlichtingenIndicator,
    inlichtingType: VipInlichtingType.SOORT_WEG_WAARLANGS_PERCEEL_GELEGEN_IS,
    version: 2,
    bronInformatie: {
      informatieverstrekker: 'Lokaal bestuur',
      laatsteWijzigingTijdstip: new Date()
    } as BronInformatie,
    kaarten: [],
    rubriek: InlichtingRubriek.OVERHEIDSPLANNEN,
    straatNaam: input.straatNaam,
    beherendeInstantie: input.beherendeInstantie as unknown as SoortWegWaarlangsPerceelGelegenIsInlichtingV2BeherendeInstantie,
    wegKlasse: input.wegKlasse as unknown as SoortWegWaarlangsPerceelGelegenIsInlichtingV2WegKlasse,
    wegCategorie: input.wegCategorie as unknown as SoortWegWaarlangsPerceelGelegenIsInlichtingV2WegCategorie,
    toegankelijkheid: input.toegankelijkheid as unknown as SoortWegWaarlangsPerceelGelegenIsInlichtingV2Toegankelijkheid
  } as SoortWegWaarlangsPerceelGelegenIsInlichtingV2
}

export default defineComponent({
  name: 'SoortWegV2',
  components: { VipInlichtingVanToepassing, VipInlichtingPreviewNew, VipInlichtingEdit },
  props: {
    inlichting: {
      type: Object as PropType<Inlichting>,
      required: false
    },
    dossierId: {
      type: String,
      required: true
    },
    dossierStatus: {
      type: Number as PropType<DossierStatus>,
      required: false
    },
    canDelete: Boolean
  },
  setup (props, { emit }) {
    const {
      refs,
      inlichtingKey,
      vipInlichting,
      inlichtingInput,
      canEdit,
      validationErrors,
      accordion,
      saving,
      toggleIndicator,
      inlichtingToggled,
      cancelEdit,
      saveInlichting,
      validationFailed
    } = useEditableInlichting(
      props.inlichting ?? ({
        inlichtingType: VipInlichtingType.SOORT_WEG_WAARLANGS_PERCEEL_GELEGEN_IS,
        version: 2
      } as unknown as SoortWegWaarlangsPerceelGelegenIsInlichtingV2),
      mapInlichtingToInput,
      mapInputToInlichting)

    const inlichtingType = TypeInlichting.SoortWegWaarlangsPerceelGelegenIsV2
    const inlichtingTitle = computed(() => inlichtingen.find(inl => inl.inlichtingType === inlichtingType).label)

    const options = new Map([
      ['instantie',
        new Map([
          ['AGENTSCHAP_MARITIEME_DIENSTVERLENING_EN_KUST', 'Agentschap Maritieme Dienstverlening en Kust'],
          ['AGENTSCHAP_VOOR_NATUUR_EN_BOS', 'Agentschap voor Natuur en Bos'],
          ['AGENTSCHAP_WEGEN_EN_VERKEER', 'Agentschap Wegen en Verkeer'],
          ['ANDERE', 'Andere'],
          ['AWV_DISTRICT_AALST', 'Agentschap Wegen en Verkeer - District Aalst'],
          ['AWV_DISTRICT_AARSCHOT', 'Agentschap Wegen en Verkeer - District Aarschot'],
          ['AWV_DISTRICT_ANTWERPEN', 'Agentschap Wegen en Verkeer - District Antwerpen'],
          ['AWV_DISTRICT_BRECHT', 'Agentschap Wegen en Verkeer - District Brecht'],
          ['AWV_DISTRICT_BRUGGE', 'Agentschap Wegen en Verkeer - District Brugge'],
          ['AWV_DISTRICT_CENTRAAL_LIMBURG', 'Agentschap Wegen en Verkeer - District Centraal-Limburg'],
          ['AWV_DISTRICT_EEKLO', 'Agentschap Wegen en Verkeer - District Eeklo'],
          ['AWV_DISTRICT_GEEL', 'Agentschap Wegen en Verkeer - District Geel'],
          ['AWV_DISTRICT_GENT', 'Agentschap Wegen en Verkeer - District Gent'],
          ['AWV_DISTRICT_HALLE', 'Agentschap Wegen en Verkeer - District Halle'],
          ['AWV_DISTRICT_IEPER', 'Agentschap Wegen en Verkeer - District Ieper'],
          ['AWV_DISTRICT_KORTRIJK', 'Agentschap Wegen en Verkeer - District Kortrijk'],
          ['AWV_DISTRICT_LEUVEN', 'Agentschap Wegen en Verkeer - District Leuven'],
          ['AWV_DISTRICT_OOSTENDE', 'Agentschap Wegen en Verkeer - District Oost-Limburg'],
          ['AWV_DISTRICT_OOST_LIMBURG', 'Agentschap Wegen en Verkeer - District Oostende'],
          ['AWV_DISTRICT_OUDENAARDE', 'Agentschap Wegen en Verkeer - District Oudenaarde'],
          ['AWV_DISTRICT_PITTEM', 'Agentschap Wegen en Verkeer - District Pittem'],
          ['AWV_DISTRICT_PUURS', 'Agentschap Wegen en Verkeer - District Puurs'],
          ['AWV_DISTRICT_RUISBROEK', 'Agentschap Wegen en Verkeer - District Ruisbroek'],
          ['AWV_DISTRICT_SINTNIKLAAS', 'Agentschap Wegen en Verkeer - District Sint-Niklaas'],
          ['AWV_DISTRICT_VILVOORDE', 'Agentschap Wegen en Verkeer - District Vilvoorde'],
          ['AWV_DISTRICT_VOSSELAAR', 'Agentschap Wegen en Verkeer - District Vosselaar'],
          ['AWV_DISTRICT_WEST_LIMBURG', 'Agentschap Wegen en Verkeer - District West-Limburg'],
          ['AWV_DISTRICT_ZUID_LIMBURG', 'Agentschap Wegen en Verkeer - District Zuid-Limburg'],
          ['BELGIE', 'Belgie'],
          ['BRUSSELS_HOOFDSTEDELIJK_GEWEST', 'Brussels Hoofdstedelijk Gewest'],
          ['DIENST_WATERLOPEN_VAN_DE_PROVINCIE', 'Dienst Waterlopen van de Provincie'],
          ['GEMEENTELIJK_HAVENBEDRIJF_ANTWERPEN', 'Gemeentelijk Havenbedrijf Antwerpen'],
          ['HAVENBEDRIJF_GENT_GAB', 'Havenbedrijf Gent GAB'],
          ['INFRABEL', 'Infrabel'],
          ['LOKAAL_BESTUUR', 'Lokaal bestuur'],
          ['MAATSCHAPPIJ_VAN_DE_BRUGSE_ZEEVAARTINRICHTINGEN', 'Maatschappij van de Brugse Zeevaartinrichtingen'],
          ['MINISTERIE_VAN_DEFENSIE', 'Ministerie van Defensie'],
          ['MOW_AFDELING_MARITIEME_TOEGANG', 'MOW Afdeling Maritieme Toegang'],
          ['NAASTGELEGEN_LOKAAL_BESTUUR', 'Naastgelegen lokaal bestuur'],
          ['NATIONALE_MAATSCHAPPIJ_DER_BELGISCHE_SPOORWEGEN', 'Nationale Maatschappij der Belgische Spoorwegen'],
          ['NIET_GEKEND', 'niet gekend'],
          ['NIEUWE_POLDER_VAN_BLANKENBERGE', 'Nieuwe polder van Blankenberge'],
          ['OOSTKUSTPOLDER', 'Oostkustpolder'],
          ['PARTICULIER_PRIVAAT_PERSOON_OF_INSTELLING', 'Particulier, privaat persoon of instelling'],
          ['POLDER_VAN_HET_LAND_VAN_WAAS', 'Polder van het Land van Waas'],
          ['PORT_OF_ANTWERP_BRUGES', 'Port of Antwerp-Bruges'],
          ['REGIE_DER_GEBOUWEN', 'Regie der gebouwen'],
          ['SINTE_CATHARINE_POLDER', 'Sinte Catharine Polder'],
          ['VLAAMSE_MILIEUMAATSCHAPPIJ', 'Vlaamse Milieumaatschappij'],
          ['VLAAMSE_VERVOERMAATSCHAPPIJ_DE_LIJN', 'Vlaamse Vervoermaatschappij De Lijn'],
          ['VLAAMSE_WATERWEG', 'Vlaamse Waterweg'],
          ['VLAAMS_MINISTERIE_VAN_MOBILITEIT_EN_OPENBARE_WERKEN_AGENTSCHAP_INFRASTRUCTUUR_AFDELING_WEGEN_EN_VERKEER_WEST_VLAANDEREN', 'Vlaams Ministerie van Mobiliteit en Openbare Werken Agentschap Infrastructuur - Afdeling wegen en verkeer West-Vlaanderen'],
          ['VLAAMS_MINISTERIE_VAN_MOBILITEIT_EN_OPENBARE_WERKEN_AGENTSCHAP_VOOR_MARITIEME_DIENSTVERLENING_EN_KUST_ADMINISTRATIE_WATERWEGEN_EN_ZEEWEZEN_AFDELING_KUST', 'Vlaams ministerie van Mobiliteit en Openbare Werken Agentschap voor Maritieme dienstverlening en kust - Administratie waterwegen en zeewezen - Afdeling Kust'],
          ['VLAAMS_MINISTERIE_VAN_MOBILITEIT_EN_OPENBARE_WERKEN_MARITIEME_TOEGANG', 'Vlaams Ministerie van Mobiliteit en Openbare Werken - Maritieme toegang'],
          ['WAALS_GEWEST', 'Waals Gewest'],
          ['WATERWEGEN_EN_ZEEKANAAL_NV_AFDELING_BOVENSCHELDE', 'Waterwegen en Zeekanaal Nv - Afdeling Bovenschelde'],
          ['VLAAMSE_WATERWEG_AFDELING_REGIO_OOST', 'De Vlaamse Waterweg Nv – Afdeling Regio Oost']
        ])
      ],
      ['klasse',
        new Map([
          ['AARDEWEG', 'Aardeweg'],
          ['AUTOSNELWEG', 'Autosnelweg'],
          ['DIENSTWEG', 'Dienstweg'],
          ['IN_OF_UITRIT_VAN_EEN_DIENST', 'In- of uitrit van een dienst'],
          ['IN_OF_UITRIT_VAN_EEN_PARKING', 'In- of uitrit van een parking'],
          ['NIET_GEKEND', 'Niet gekend'],
          ['OP_OF_AFRIT_BEHORENDE_TOT_EEN_GELIJKGRONDSE_VERBINDING', 'Op- of afrit behorende tot een niet gelijkgrondse verbinding'],
          ['OP_OF_AFRIT_BEHORENDE_TOT_EEN_NIET_GELIJKGRONDSE_VERBINDING', 'Op- of afrit behorende tot een gelijkgrondse verbinding'],
          ['PARALLELWEG', 'Parallelweg'],
          ['ROTONDE', 'Rotonde'],
          ['SPECIALE_VERKEERSSITUATIE', 'Speciale verkeerssituatie'],
          ['TRAMWEG_NIET_TOEGANKELIJK_VOOR_ANDERE_VOERTUIGEN', 'Tramweg niet toegankelijk voor andere voertuigen'],
          ['VEER', 'Veer'],
          ['VENTWEG', 'Ventweg'],
          ['VERKEERSPLEIN', 'Verkeersplein'],
          ['VOETGANGERSZONE', 'Voetgangerszone'],
          ['WANDEL_OF_FIETSWEG_NIET_TOEGANKELIJK_VOOR_ANDERE_VOERTUIGEN', 'Wandel- en/of fietsweg niet toegankelijk voor andere voertuigen'],
          ['WEG_BESTAANDE_UIT_EEN_RIJBAAN', 'Weg bestaande uit een rijbaan'],
          ['WEG_MET_GESCHEIDEN_RIJBANEN_DIE_GEEN_AUTOSNELWEG_IS', 'Weg met gescheiden rijbanen die geen autosnelweg is']
        ])
      ],
      ['categorie',
        new Map([
          ['HOOFDWEG', 'Hoofdweg'],
          ['LOKALE_WEG', 'Lokale weg'],
          ['LOKALE_WEG_TYPE_1', 'Lokale weg type 1'],
          ['LOKALE_WEG_TYPE_2', 'Lokale weg type 2'],
          ['LOKALE_WEG_TYPE_3', 'Lokale weg type 3'],
          ['NIET_GEKEND', 'Niet gekend'],
          ['NIET_VAN_TOEPASSING', 'Niet van toepassing'],
          ['PRIMAIRE_WEG_I', 'Primaire weg I'],
          ['PRIMAIRE_WEG_II', 'Primaire weg II'],
          ['PRIMAIRE_WEG_II_TYPE_1', 'Primaire weg II type 1'],
          ['PRIMAIRE_WEG_II_TYPE_2', 'Primaire weg II type 2'],
          ['PRIMAIRE_WEG_II_TYPE_3', 'Primaire weg II type 3'],
          ['PRIMAIRE_WEG_II_TYPE_4', 'Primaire weg II type 4'],
          ['SECUNDAIRE_WEG_TYPE', 'Secundaire weg type'],
          ['SECUNDAIRE_WEG_TYPE_1', 'Secundaire weg type 1'],
          ['SECUNDAIRE_WEG_TYPE_2', 'Secundaire weg type 2'],
          ['SECUNDAIRE_WEG_TYPE_3', 'Secundaire weg type 3'],
          ['SECUNDAIRE_WEG_TYPE_4', 'Secundaire weg type 4']
        ])
      ],
      ['toegankelijkheid',
        new Map([
          ['ONMOGELIJKE_TOEGANG', 'Onmogelijke toegang'],
          ['OPENBARE_WEG', 'Openbare weg'],
          ['PRIVAATWEG', 'Privaatweg'],
          ['SEIZOENSGEBONDEN_TOEGANG', 'Seizoensgebonden toegang'],
          ['TOLWEG', 'Tolweg'],
          ['VERBODEN_TOEGANG', 'Verboden toegang']
        ])
      ]
    ])

    const validateInput = (): Map<string, string> => {
      const errors = new Map<string, string>()
      if (!inlichtingInput.value.straatNaam) {
        errors.set('straat', 'Straatnaam is verplicht')
      }
      if (!inlichtingInput.value.beherendeInstantie) {
        errors.set('instantie', 'Beherende instantie is verplicht')
      }
      if (!inlichtingInput.value.wegKlasse) {
        errors.set('klasse', 'Wegklasse is verplicht')
      }
      if (!inlichtingInput.value.wegCategorie) {
        errors.set('categorie', 'Wegcategorie is verplicht')
      }
      if (!inlichtingInput.value.toegankelijkheid) {
        errors.set('toegankelijkheid', 'Toegankelijkheid is verplicht')
      }
      return errors
    }

    const saved = (inlichtingId: string) => {
      saveInlichting(inlichtingId)
      emit('saved', vipInlichting.value)
      inlichtingToggled(true)
    }

    const removed = () => {
      emit('removed', vipInlichting.value.inlichtingId)
      EventBus.$emit('close-modal', 'vl-modal-backdrop')
    }

    const cancelled = () => {
      cancelEdit()
      emit('cancelled')
    }

    return {
      vipInlichting,
      inlichtingInput,
      validationErrors,
      saving,
      accordion,
      refs,
      inlichtingKey,
      canEdit,
      inlichtingType,
      inlichtingTitle,
      options,
      inlichtingToggled,
      toggleIndicator,
      validateInput,
      validationFailed,
      saved,
      removed,
      cancelled,
      formatNumber,
      formatDate
    }
  }
})
