import {
  ExploitatieVanIngedeeldeInrichtingenOfActiviteiten,
  ExploitatieVanIngedeeldeInrichtingenOfActiviteitenActiviteit,
  ExploitatieVanIngedeeldeInrichtingenOfActiviteitenActiviteitStatus,
  ExploitatieVanIngedeeldeInrichtingenOfActiviteitenActiviteitVlareboCode
} from '@/infrastructure/bff-client/bff-client'
import { defineComponent, PropType, readonly } from 'vue'
import useOmgevingVergunningLabels from '../../composables/omgevingsvergunning-labels'
import { formatDate } from '@/infrastructure/filters/filters'

export default defineComponent({
  name: 'exploitatie-preview',
  props: {
    previewData: Object as PropType<ExploitatieVanIngedeeldeInrichtingenOfActiviteiten>
  },
  setup (props) {
    const preview = readonly(props.previewData as ExploitatieVanIngedeeldeInrichtingenOfActiviteiten)
    const { options, getLabel, vervalTekst } = useOmgevingVergunningLabels()
    const checkOrCross = (activiteitStatus: ExploitatieVanIngedeeldeInrichtingenOfActiviteitenActiviteitStatus): string => {
      return activiteitStatus === ExploitatieVanIngedeeldeInrichtingenOfActiviteitenActiviteitStatus.VERGUND ? 'check' : 'cross'
    }
    const getActiviteitTekstFormatted = (activiteit: ExploitatieVanIngedeeldeInrichtingenOfActiviteitenActiviteit) => {
      let omschrijving = ''
      if (activiteit.vlareboCode &&
          activiteit.vlareboCode !== ExploitatieVanIngedeeldeInrichtingenOfActiviteitenActiviteitVlareboCode.NIET_GEKEND &&
          activiteit.vlareboCode !== ExploitatieVanIngedeeldeInrichtingenOfActiviteitenActiviteitVlareboCode.NIET_GEVONDEN) {
        omschrijving += '<abbr title="' + getLabel('vlarboCodeDescription', activiteit.vlareboCode) + '">' + getLabel('vlarboCode', activiteit.vlareboCode) + '</abbr>'
      } else if (activiteit.vlareboCode) {
        omschrijving += getLabel('vlarboCode', activiteit.vlareboCode) + '</abbr>'
      }
      if (activiteit.risicoklasse) {
        omschrijving += `${activiteit.vlareboCode ? '$' : ''}` + getLabel('exploitatieKlasse', activiteit.risicoklasse)
      }
      if (activiteit.status) {
        omschrijving += `${activiteit.vlareboCode || activiteit.risicoklasse ? '$' : ''}` + getLabel('beslissingsType', activiteit.status)
      }
      if (activiteit.startdatum || activiteit.einddatum) {
        omschrijving += '$' + getGeldigPeriodeTekst(activiteit.startdatum, activiteit.einddatum, true)
      }
      return omschrijving.replaceAll('$', ' - ')
    }

    const getGeldigPeriodeTekst = (geldigVan: Date, geldigTotEnMet: Date, totEnMet: boolean = false) => {
      let geldigVanTekst = ''
      if (geldigVan) {
        geldigVanTekst = ' van ' + formatDate(geldigVan)
      }
      let geldigTotEnMetTekst = ''
      if (geldigTotEnMet) {
        geldigTotEnMetTekst = `${totEnMet ? ' t.e.m. ' : ' tot '}` + formatDate(geldigTotEnMet)
      }
      return 'Geldig' + geldigVanTekst + geldigTotEnMetTekst
    }

    return { options, preview, getLabel, formatDate, checkOrCross, getActiviteitTekstFormatted, getGeldigPeriodeTekst, vervalTekst }
  }
})
