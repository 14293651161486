import { computed, defineComponent, PropType } from 'vue'
import {
  ArrestVanRaadVanState,
  BronInformatie,
  DossierStatus,
  InlichtingenIndicator,
  InlichtingRubriek,
  OmgevingsvergunningBeslissing,
  OmgevingsvergunningInlichting,
  OmgevingsvergunningInlichtingLopendeProcedure,
  OmgevingsvergunningInput,
  OmgevingsvergunningInputLopendeProcedure,
  TypeInlichting,
  VergunningBeslissingType,
  VipInlichtingDiscriminator,
  VipInlichtingType,
} from '@/infrastructure/bff-client/bff-client'
import VipInlichtingVanToepassing from '@/components/vip-inlichting/components/vip-inlichting-van-toepassing/vip-inlichting-van-toepassing.vue'
import { formatDate, formatNumber } from '@/infrastructure/filters/filters'
import EventBus from '@/infrastructure/events/event-bus'
import OmgevingsvergunningEdit from './edit/omgevingsvergunning-edit.vue'
import useEditableInlichting from '@/components/vip-inlichting/new-version/composables/editable-inlichting.composable'
import VipInlichtingPreviewNew from '@/components/vip-inlichting/new-version/components/inlichting-preview/vip-inlichting-preview-new.vue'
import VipInlichtingEdit from '@/components/vip-inlichting/new-version/components/inlichting-edit/vip-inlichting-edit.vue'
import OmgevingsvergunningPreview from './preview/omgevingsvergunning-preview.vue'

export interface Beslissing {
  datumBeslissing: string
  beslissingType: VergunningBeslissingType
  type: string
  instantie: string
}

function mapInlichtingToInput (vipInlichting?: OmgevingsvergunningInlichting): OmgevingsvergunningInput {
  const copy = structuredClone(vipInlichting)
  return {
    discriminator: VipInlichtingDiscriminator.OMGEVINGSVERGUNNING_V1,
    inlichtingenIndicator: copy === null ? InlichtingenIndicator.NEE : copy.inlichtingenIndicator,
    inlichtingType: VipInlichtingType.OMGEVINGSVERGUNNING,
    beschrijving: copy.beschrijving,
    bijlage: copy.bijlagen,
    omvNummer: copy.omvNummer,
    projectType: copy.projectType,
    voorwaarden: copy.voorwaarden,
    lopendeProcedure: copy.lopendeProcedure as unknown as OmgevingsvergunningInputLopendeProcedure,
    beslissingen: copy.beslissingen,
    stedenbouwkundigeHandelingen: copy.stedenbouwkundigeHandelingen,
    verkavelingenVanGronden: copy.verkavelingenVanGronden,
    exploitatiesVanIngedeeldeInrichtingenOfActiviteiten: copy.exploitatiesVanIngedeeldeInrichtingenOfActiviteiten,
    kleinhandelsactiviteiten: copy.kleinhandelsactiviteiten,
    wijzigingenVanKleineLandschapselementenOfVegetatie: copy.wijzigingenVanKleineLandschapselementenOfVegetatie,

  } as OmgevingsvergunningInput
}

function mapInputToInlichting (input: OmgevingsvergunningInput, inlichtingId: string): OmgevingsvergunningInlichting {
  const copy = structuredClone(input)
  return {
    inlichtingId,
    inlichtingenIndicator: copy.inlichtingenIndicator as unknown as InlichtingenIndicator,
    discriminator: VipInlichtingDiscriminator.OMGEVINGSVERGUNNING_V1,
    inlichtingType: VipInlichtingType.OMGEVINGSVERGUNNING,
    version: 1,
    rubriek: InlichtingRubriek.VERGUNNINGEN,
    bronInformatie: {
      informatieverstrekker: 'Lokaal bestuur',
      laatsteWijzigingTijdstip: new Date()
    } as BronInformatie,
    kaarten: [],
    bijlagen: copy.bijlage,
    beschrijving: copy.beschrijving,
    omvNummer: copy.omvNummer,
    projectType: copy.projectType,
    voorwaarden: copy.voorwaarden,
    lopendeProcedure: copy.lopendeProcedure as unknown as OmgevingsvergunningInlichtingLopendeProcedure,
    beslissingen: copy.beslissingen as unknown as OmgevingsvergunningBeslissing[],
    stedenbouwkundigeHandelingen: copy.stedenbouwkundigeHandelingen,
    verkavelingenVanGronden: copy.verkavelingenVanGronden,
    exploitatiesVanIngedeeldeInrichtingenOfActiviteiten: copy.exploitatiesVanIngedeeldeInrichtingenOfActiviteiten,
    kleinhandelsactiviteiten: copy.kleinhandelsactiviteiten,
    wijzigingenVanKleineLandschapselementenOfVegetatie: copy.wijzigingenVanKleineLandschapselementenOfVegetatie,
  } as OmgevingsvergunningInlichting
}

export default defineComponent({
  name: 'Omgevingsvergunning',
  components: {
    OmgevingsvergunningEdit,
    VipInlichtingPreviewNew,
    VipInlichtingEdit,
    VipInlichtingVanToepassing,
    OmgevingsvergunningPreview,
  },
  props: {
    inlichting: {
      type: Object as PropType<OmgevingsvergunningInlichting>,
      required: false,
    },
    dossierId: {
      type: String,
      required: true,
    },
    dossierStatus: {
      type: Number as PropType<DossierStatus>,
      required: false,
    },
    canDelete: Boolean,
  },
  setup (props, { emit }) {
    const {
      refs,
      vipInlichting,
      inlichtingInput,
      canEdit,
      validationErrors,
      bijlageUploaded,
      addedBijlagen,
      accordion,
      saving,
      inlichtingKey,
      toggleIndicator,
      inlichtingToggled,
      cancelEdit,
      saveInlichting,
      downloadUrl,
    } = useEditableInlichting(
      props.inlichting ??
        ({ inlichtingType: VipInlichtingType.OMGEVINGSVERGUNNING } as unknown as OmgevingsvergunningInlichting),
      mapInlichtingToInput,
      mapInputToInlichting
    )

    const inlichtingType = TypeInlichting.Omgevingsvergunning
    const inlichtingTitle = 'Omgevingsvergunning'
    const isNietGekend = computed(() => vipInlichting.value.inlichtingenIndicator === InlichtingenIndicator.NIET_GEKEND)

    const saved = (inlichtingId: string) => {
      saveInlichting(inlichtingId)
      emit('saved', vipInlichting.value)
    }

    const validateInput = (): Map<string, string> => {
      const errors = new Map<string, string>()
      const inputToCheck = inlichtingInput.value as OmgevingsvergunningInput
      if (inputToCheck.inlichtingenIndicator === InlichtingenIndicator.JA) {
        if (!inputToCheck.beschrijving) {
          errors.set('beschrijving', 'Beschrijving is verplicht')
        }
        if (!inputToCheck.omvNummer) {
          errors.set('omvNummer', 'OMV nummer is verplicht')
        }
        if (inputToCheck.lopendeProcedure == null) {
          errors.set('lopendeProcedure', 'Lopende procedure is verplicht')
        }
        if ((inputToCheck.stedenbouwkundigeHandelingen ?? []).length === 0 &&
            (inputToCheck.verkavelingenVanGronden ?? []).length === 0 &&
            (inputToCheck.exploitatiesVanIngedeeldeInrichtingenOfActiviteiten ?? []).length === 0 &&
            (inputToCheck.kleinhandelsactiviteiten ?? []).length === 0 &&
            (inputToCheck.wijzigingenVanKleineLandschapselementenOfVegetatie ?? []).length === 0) {
          errors.set('projectElementen', 'Minstens 1 project element is verplicht')
        }

        if (inputToCheck.beslissingen?.length > 0) {
          inputToCheck.beslissingen?.forEach((beslissing: OmgevingsvergunningBeslissing, index: number) => {
            const mappedBeslissing = beslissing as Beslissing
            if (mappedBeslissing.beslissingType === VergunningBeslissingType.ARREST_VAN_RAAD_VOOR_VERGUNNINGBETWISTING ||
                mappedBeslissing.beslissingType === VergunningBeslissingType.ARREST_VAN_RAAD_VAN_STATE) {
              if ((beslissing as ArrestVanRaadVanState).datumArrest == null) {
                errors.set(`beslissingen-${index}-datumArrest`, 'Datum beslissing is verplicht')
              }
            } else {
              if (mappedBeslissing.datumBeslissing == null) {
                errors.set(`beslissingen-${index}-datumBeslissing`, 'Datum beslissing is verplicht')
              }
              if (mappedBeslissing.instantie == null) {
                errors.set(`beslissingen-${index}-instantie`, 'Instantie is verplicht')
              }
            }
            if (mappedBeslissing.type == null) {
              errors.set(`beslissingen-${index}-type`, 'Type is verplicht')
            }
          })
        }
        if ((inputToCheck.verkavelingenVanGronden ?? []).length > 0) {
          inputToCheck.verkavelingenVanGronden.forEach((verkaveling, index) => {
            if (!verkaveling.type) {
              errors.set(`verkavelingenVanGronden-${index}-type`, 'Aard van de aanvraag is verplicht')
            }
            if (verkaveling.verval && !verkaveling.verval?.status) {
              errors.set(`verkavelingenVanGronden-${index}-verval-status`, 'Status is verplicht') // -verval-status is nodig om de juiste error te tonen in verval component
            }
            if (verkaveling.lotInformatie.length > 0) {
              verkaveling.lotInformatie.forEach((lot, lotIndex) => {
                if (!lot.nummer) {
                  errors.set(`verkavelingenVanGronden-${index}-lotInformatie-${lotIndex}-nummer`, 'Lot nummer is verplicht')
                }
                if (!lot.status) {
                  errors.set(`verkavelingenVanGronden-${index}-lotInformatie-${lotIndex}-status`, 'Status is verplicht')
                }
              })
            }
          })
        }
        if ((inputToCheck.exploitatiesVanIngedeeldeInrichtingenOfActiviteiten ?? []).length > 0) {
          inputToCheck.exploitatiesVanIngedeeldeInrichtingenOfActiviteiten.forEach((exploitatie, index) => {
            if (!exploitatie.inrichtingsnummer) {
              errors.set(`exploitaties-${index}-inrichtingsnummer`, 'Inrichtingsnummer is verplicht')
            }
            if (exploitatie.verval && !exploitatie.verval?.status) {
              errors.set(`exploitaties-${index}-verval-status`, 'Status is verplicht')
            }
            if ((exploitatie.activiteiten ?? []).length > 0) {
              exploitatie.activiteiten.forEach((activiteit, activiteitIndex) => {
                if (!activiteit.rubriek) {
                  errors.set(`exploitaties-${index}-activiteiten-${activiteitIndex}-rubriek`, 'Rubriek is verplicht')
                }
              })
            }
          })
        }
        if ((inputToCheck.kleinhandelsactiviteiten ?? []).length > 0) {
          inputToCheck.kleinhandelsactiviteiten.forEach((kleinhandel, index) => {
            if (!kleinhandel.type) {
              errors.set(`kleinhandelsactiviteiten-${index}-type`, 'Verkoop van type is verplicht')
            }
          })
        }
        if ((inputToCheck.wijzigingenVanKleineLandschapselementenOfVegetatie ?? []).length > 0) {
          inputToCheck.wijzigingenVanKleineLandschapselementenOfVegetatie.forEach((wijziging, index) => {
            if (!wijziging.omschrijving) {
              errors.set(`wijziging-omgeving-${index}-omschrijving`, 'Beschrijving is verplicht')
            }
          })
        }
        if ((inputToCheck.stedenbouwkundigeHandelingen ?? []).length > 0) {
          inputToCheck.stedenbouwkundigeHandelingen.forEach((handeling, index) => {
            if (handeling.verval && !handeling.verval?.status) {
              errors.set(`stedenbouwkundige-handelingen-${index}-verval-status`, 'Status is verplicht')
            }
          })
        }
      }
      return errors
    }

    const validationFailed = (errors: Map<string, string>) => {
      validationErrors.value = errors
    }

    const inputUpdated = (input: OmgevingsvergunningInput) => {
      inlichtingInput.value = input
    }

    const removed = () => {
      emit('removed', vipInlichting.value.inlichtingId)
      EventBus.$emit('close-modal', 'vl-modal-backdrop')
    }

    const cancelled = () => {
      emit('cancelled')
    }

    return {
      vipInlichting,
      validateInput,
      validationFailed,
      inputUpdated,
      refs,
      inlichtingInput,
      canEdit,
      validationErrors,
      accordion,
      saving,
      inlichtingKey,
      inlichtingType,
      toggleIndicator,
      inlichtingToggled,
      cancelEdit,
      saveInlichting,
      downloadUrl,
      isNietGekend,
      addedBijlagen,
      bijlageUploaded,
      saved,
      removed,
      cancelled,
      formatNumber,
      formatDate,
      inlichtingTitle,
    }
  },
})
