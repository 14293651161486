import { computed, defineComponent, PropType, ref, watch } from 'vue'
import VipInlichtingVanToepassing
  from '@/components/vip-inlichting/components/vip-inlichting-van-toepassing/vip-inlichting-van-toepassing.vue'
import {
  BronInformatie,
  DossierStatus,
  GemeentelijkRooilijnplanInlichting,
  GemeentelijkRooilijnplanInlichtingGetroffenDoorRooilijn,
  GemeentelijkRooilijnplanInlichtingOorsprongRooilijn,
  GemeentelijkRooilijnplanInput,
  GemeentelijkRooilijnplanInputGetroffenDoorRooilijn,
  GemeentelijkRooilijnplanInputOorsprongRooilijn,
  GemeentelijkRooilijnplanPlanfase,
  Inlichting,
  InlichtingenIndicator,
  InlichtingRubriek,
  TypeInlichting,
  VipInlichtingDiscriminator,
  VipInlichtingType
} from '@/infrastructure/bff-client/bff-client'
import VipInlichtingPreviewNew
  from '@/components/vip-inlichting/new-version/components/inlichting-preview/vip-inlichting-preview-new.vue'
import { formatDate, formatNumber } from '@/infrastructure/filters/filters'
import { inlichtingen } from '@/infrastructure/constants/inlichting-types-constants'
import useEditableInlichting from '@/components/vip-inlichting/new-version/composables/editable-inlichting.composable'
import EventBus from '@/infrastructure/events/event-bus'
import VipInlichtingEdit
  from '@/components/vip-inlichting/new-version/components/inlichting-edit/vip-inlichting-edit.vue'

function mapInlichtingToInput (vipInlichting?: GemeentelijkRooilijnplanInlichting): GemeentelijkRooilijnplanInput {
  return {
    inlichtingenIndicator: vipInlichting === null ? InlichtingenIndicator.NEE : vipInlichting.inlichtingenIndicator,
    referentie: vipInlichting?.referentie,
    beschrijving: vipInlichting?.beschrijving,
    externeDocumentatie: vipInlichting?.externeDocumentatie,
    discriminator: VipInlichtingDiscriminator.GEMEENTELIJK_ROOILIJNPLAN_V1,
    inlichtingType: VipInlichtingType.GEMEENTELIJK_ROOILIJNPLAN,
    oorsprongRooilijn: vipInlichting?.oorsprongRooilijn as unknown as GemeentelijkRooilijnplanInputOorsprongRooilijn,
    getroffenDoorRooilijn: vipInlichting?.getroffenDoorRooilijn as unknown as GemeentelijkRooilijnplanInputGetroffenDoorRooilijn
  } as GemeentelijkRooilijnplanInput
}

function mapInputToInlichting (input: GemeentelijkRooilijnplanInput, inlichtingId: string): GemeentelijkRooilijnplanInlichting {
  return {
    inlichtingId,
    inlichtingenIndicator: input.inlichtingenIndicator as unknown as InlichtingenIndicator,
    inlichtingType: VipInlichtingType.GEMEENTELIJK_ROOILIJNPLAN,
    version: 1,
    bronInformatie: {
      informatieverstrekker: 'Lokaal bestuur',
      laatsteWijzigingTijdstip: new Date()
    } as BronInformatie,
    kaarten: [],
    rubriek: InlichtingRubriek.OVERHEIDSPLANNEN,
    referentie: input.referentie,
    beschrijving: input.beschrijving,
    planfase: input.planfase,
    externeDocumentatie: input.externeDocumentatie,
    oorsprongRooilijn: input.oorsprongRooilijn as unknown as GemeentelijkRooilijnplanInlichtingOorsprongRooilijn,
    getroffenDoorRooilijn: input.getroffenDoorRooilijn as unknown as GemeentelijkRooilijnplanInlichtingGetroffenDoorRooilijn
  } as GemeentelijkRooilijnplanInlichting
}

export default defineComponent({
  name: 'Rooilijnplan',
  components: { VipInlichtingVanToepassing, VipInlichtingPreviewNew, VipInlichtingEdit },
  props: {
    inlichting: {
      type: Object as PropType<Inlichting>,
      required: false
    },
    dossierId: {
      type: String,
      required: true
    },
    dossierStatus: {
      type: Number as PropType<DossierStatus>,
      required: false
    },
    canDelete: Boolean
  },
  setup (props, { emit }) {
    const {
      refs,
      inlichtingKey,
      vipInlichting,
      inlichtingInput,
      canEdit,
      validationErrors,
      accordion,
      saving,
      addedBijlagen,
      isNietGekend,
      bijlageUploaded,
      toggleIndicator,
      inlichtingToggled,
      cancelEdit,
      saveInlichting,
      validationFailed,
      downloadUrl,
      bijlagenSaved,
      bijlageDeleted
    } = useEditableInlichting(
      props.inlichting ?? ({ inlichtingType: VipInlichtingType.GEMEENTELIJK_ROOILIJNPLAN } as unknown as GemeentelijkRooilijnplanInlichting),
      mapInlichtingToInput,
      mapInputToInlichting)

    const inlichtingType = TypeInlichting.GemeentelijkRooilijnplan
    const inlichtingTitle = computed(() => inlichtingen.find(inl => inl.inlichtingType === inlichtingType).label)

    const planfaseInput = ref((vipInlichting?.value.planfase ?? {}) as GemeentelijkRooilijnplanPlanfase)

    const options = new Map([
      ['planfase',
        new Map([
          ['', ''],
          ['START', 'Start'],
          ['PLENAIRE_VERGADERING', 'Plenaire vergadering'],
          ['SCOPING', 'Scoping'],
          ['ONTWERP_LOPEND_DOSSIER', 'Ontwerp lopend dossier'],
          ['VOORLOPIGE_VASTSTELLING', 'Voorlopige vaststelling'],
          ['DEFINITIEVE_VASTSTELLING', 'Definitieve vaststelling'],
          ['BESLUIT_TOT_GOEDKEURING', 'Besluit tot goedkeuring'],
          ['NATRAJECT', 'Natraject'],
          ['ONTWERP_PROCEDURE_STOPGEZET', 'Ontwerp procedure stopgezet'],
        ])
      ],
      ['oorsprongRooilijn',
        new Map([
          ['', ''],
          ['AFPALINGSPLAN', 'Afpalingsplan'],
          ['BESTEMMINGSPLAN', 'Bestemmingsplan'],
          ['BUURTWEGWIJZIGING', 'Buurtwegwijziging'],
          ['GEMEENTELIJK_ROOILIJNPLAN', 'Gemeentelijk rooilijnplan'],
          ['GEWESTELIJK_ROOILIJNPLAN', 'Gewestelijk rooilijnplan'],
          ['PLAN_VAN_DE_OVERDRACHT_VAN_DE_WEGZATE', 'Plan van de overdracht van de wegzate'],
          ['PROVINCIAAL_ROOILIJNPLAN', 'Provinciaal rooilijnplan'],
          ['STEDENBOUWKUNDIGE_OF_OMGEVINGSVERGUNNING', 'Stedenbouwkundige-/Omgevingsvergunning'],
          ['VERKAVELINGSPLAN', 'Verkavelingsplan'],
          ['RUILVERKAVELINGSPLAN', 'Ruilverkavelingsplan'],
        ])
      ],
      ['getroffenDoorRooilijn',
        new Map([
          ['', ''],
          ['GEBOUW_GETROFFEN', 'Gebouw/constructie getroffen'],
          ['NIET_GETROFFEN', 'Niet getroffen'],
          ['ONDERZOEK_LANDMETER', 'Onderzoek door landmeter aangewezen'],
          ['PERCEEL_GETROFFEN', 'Perceel getroffen'],
          ['VOOR_ZOVER_IS_NA_TE_GAAN_ZONDER_LANDMEETKUNDIGE_OPMETINGEN', 'Voor zover is na te gaan zonder landmeetkundige opmetingen'],
        ])
      ]
    ])

    const validateInput = (): Map<string, string> => {
      const errors = new Map<string, string>()
      if (!inlichtingInput.value.beschrijving) {
        errors.set('plannaam', 'Plannaam is verplicht')
      }
      return errors
    }

    const saved = (inlichtingId: string) => {
      saveInlichting(inlichtingId)
      emit('saved', vipInlichting.value)
      inlichtingToggled(true)
    }

    const removed = () => {
      emit('removed', vipInlichting.value.inlichtingId)
      EventBus.$emit('close-modal', 'vl-modal-backdrop')
    }
    const cancelled = () => {
      cancelEdit()
      emit('cancelled')
    }

    watch(() => inlichtingInput.value.inlichtingenIndicator, (newValue) => {
      if (newValue === InlichtingenIndicator.NEE) {
        inlichtingInput.value.planfase = null
      }
    }, { immediate: true })

    watch(() => planfaseInput.value, (newValue: GemeentelijkRooilijnplanPlanfase) => {
      if (newValue.status == null) {
        inlichtingInput.value.planfase = null
      } else {
        inlichtingInput.value.planfase = newValue
      }
    }, { immediate: true, deep: true })

    return {
      vipInlichting,
      inlichtingInput,
      validationErrors,
      saving,
      accordion,
      refs,
      inlichtingKey,
      canEdit,
      addedBijlagen,
      inlichtingType,
      inlichtingTitle,
      options,
      isNietGekend,
      planfaseInput,
      bijlageUploaded,
      inlichtingToggled,
      toggleIndicator,
      validateInput,
      validationFailed,
      saved,
      removed,
      cancelled,
      downloadUrl,
      bijlagenSaved,
      bijlageDeleted,
      formatNumber,
      formatDate
    }
  }
})
