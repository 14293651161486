import { computed, defineComponent, PropType, watch } from 'vue'
import VipInlichtingVanToepassing
  from '@/components/vip-inlichting/components/vip-inlichting-van-toepassing/vip-inlichting-van-toepassing.vue'
import {
  BijkomendeDocumentatie,
  BronInformatie,
  DossierStatus,
  GewestplanV2Inlichting,
  GewestplanV2Input,
  Inlichting,
  InlichtingenIndicator,

  InlichtingRubriek,
  Planfase,
  TypeInlichting,
  VipInlichtingDiscriminator,
  VipInlichtingType
} from '@/infrastructure/bff-client/bff-client'
import VipInlichtingPreviewNew
  from '@/components/vip-inlichting/new-version/components/inlichting-preview/vip-inlichting-preview-new.vue'
import { formatDate, formatNumber } from '@/infrastructure/filters/filters'
import { inlichtingen } from '@/infrastructure/constants/inlichting-types-constants'
import useEditableInlichting from '@/components/vip-inlichting/new-version/composables/editable-inlichting.composable'
import EventBus from '@/infrastructure/events/event-bus'
import BijkomendeDocumentaties
  from '@/components/vip-inlichting/new-version/components/inlichtingen/overheidsplannen/gewestplan/v2/bijkomende-documentatie/bijkomende-documentaties.vue'
import VipInlichtingEdit
  from '@/components/vip-inlichting/new-version/components/inlichting-edit/vip-inlichting-edit.vue'
import { voorschriften } from '@/components/vip-inlichting/new-version/components/inlichtingen/overheidsplannen/voorschriften-options'

function mapInlichtingToInput (vipInlichting?: GewestplanV2Inlichting): GewestplanV2Input {
  return {
    inlichtingenIndicator: vipInlichting === null ? InlichtingenIndicator.NEE : vipInlichting.inlichtingenIndicator,
    referentie: vipInlichting?.referentie,
    beschrijving: vipInlichting?.beschrijving,
    planfase: vipInlichting?.planfase ?? {} as Planfase,
    externeDocumentatie: vipInlichting?.externeDocumentatie,
    bestemmingen: vipInlichting?.bestemmingen,
    discriminator: VipInlichtingDiscriminator.GEWESTPLAN_V2,
    inlichtingType: VipInlichtingType.GEWESTPLAN,
    bijkomendeDocumentatie: vipInlichting?.bijkomendeDocumentatie ?? []
  } as GewestplanV2Input
}

function mapInputToInlichting (input: GewestplanV2Input, inlichtingId: string): GewestplanV2Inlichting {
  return {
    inlichtingId,
    inlichtingenIndicator: input.inlichtingenIndicator as unknown as InlichtingenIndicator,
    inlichtingType: VipInlichtingType.GEWESTPLAN,
    version: 2,
    bronInformatie: {
      informatieverstrekker: 'Lokaal bestuur',
      laatsteWijzigingTijdstip: new Date()
    } as BronInformatie,
    kaarten: [],
    rubriek: InlichtingRubriek.OVERHEIDSPLANNEN,
    referentie: input.referentie,
    beschrijving: input.beschrijving,
    planfase: input.planfase,
    externeDocumentatie: input.externeDocumentatie,
    bestemmingen: input.bestemmingen,
    bijkomendeDocumentatie: input.bijkomendeDocumentatie,
  } as GewestplanV2Inlichting
}

export default defineComponent({
  name: 'GewestplanV2',
  components: { BijkomendeDocumentaties, VipInlichtingVanToepassing, VipInlichtingPreviewNew, VipInlichtingEdit },
  props: {
    inlichting: {
      type: Object as PropType<Inlichting>,
      required: false
    },
    dossierId: {
      type: String,
      required: true
    },
    dossierStatus: {
      type: Number as PropType<DossierStatus>,
      required: false
    },
    canDelete: Boolean
  },
  setup (props, { emit }) {
    const {
      refs,
      inlichtingKey,
      vipInlichting,
      inlichtingInput,
      canEdit,
      validationErrors,
      accordion,
      saving,
      addedBijlagen,
      isNietGekend,
      bijlageUploaded,
      toggleIndicator,
      inlichtingToggled,
      cancelEdit,
      saveInlichting,
      validationFailed,
      downloadUrl,
      bijlagenSaved,
      bijlageDeleted
    } = useEditableInlichting(
      props.inlichting ?? ({ inlichtingType: VipInlichtingType.GEWESTPLAN } as unknown as GewestplanV2Inlichting),
      mapInlichtingToInput,
      mapInputToInlichting)

    const inlichtingType = TypeInlichting.GewestplanV2
    const inlichtingTitle = computed(() => inlichtingen.find(inl => inl.inlichtingType === inlichtingType).label)

    const voorschriftSuggesties: string[] = voorschriften

    const planfaseOptions =
        new Map([
          ['BESLUIT_TOT_GOEDKEURING', 'Besluit tot goedkeuring']
        ])

    const validateInput = (): Map<string, string> => {
      const errors = new Map<string, string>()
      if (!inlichtingInput.value.beschrijving) {
        errors.set('plannaam', 'Plannaam is verplicht')
      }
      if (!inlichtingInput.value.referentie) {
        errors.set('planreferentie', 'Planreferentie is verplicht')
      }
      if (!inlichtingInput.value.planfase.status) {
        errors.set('planfase', 'Planstatus is verplicht')
      }
      if ((inlichtingInput.value.bestemmingen ?? []).length === 0) {
        errors.set('voorschriften', 'Stedenbouwkundige voorschriften is verplicht')
      }
      inlichtingInput.value.bijkomendeDocumentatie.forEach((documentatie: BijkomendeDocumentatie, index: number) => {
        if (!documentatie.titel) {
          errors.set(`bijkomende-documentatie-${index}-titel`, 'Titel is verplicht')
        }
        if (!documentatie.classificatie) {
          errors.set(`bijkomende-documentatie-${index}-classificatie`, 'Classificatie is verplicht')
        }
        if (!documentatie.omschrijving) {
          errors.set(`bijkomende-documentatie-${index}-omschrijving`, 'Omschrijving is verplicht')
        }
        if (!documentatie.link) {
          errors.set(`bijkomende-documentatie-${index}-link`, 'Link is verplicht')
        }
      })
      return errors
    }

    const saved = (inlichtingId: string) => {
      saveInlichting(inlichtingId)
      emit('saved', vipInlichting.value)
      inlichtingToggled(true)
    }

    const removed = () => {
      emit('removed', vipInlichting.value.inlichtingId)
      EventBus.$emit('close-modal', 'vl-modal-backdrop')
    }

    const cancelled = () => {
      cancelEdit()
      emit('cancelled')
    }

    const addVoorschrift = (bestemming: string) => {
      voorschriftSuggesties.push(bestemming)
      inlichtingInput.value.bestemmingen.push(bestemming)
    }

    const updateDocumentaties = (bijkomendeDocumentaties: BijkomendeDocumentatie[]) => {
      inlichtingInput.value.bijkomendeDocumentatie = bijkomendeDocumentaties
    }

    watch(() => inlichtingInput.value.inlichtingenIndicator, (newValue: InlichtingenIndicator) => {
      if (newValue === InlichtingenIndicator.NEE) {
        inlichtingInput.value.planfase = null
      } else {
        inlichtingInput.value.planfase = {} as Planfase
      }
    })

    watch(() => inlichtingInput.value.inlichtingenIndicator, (newValue) => {
      if (newValue === InlichtingenIndicator.NEE) {
        inlichtingInput.value.planfase = null
        inlichtingInput.value.externeDocumentatie = null
      } else if (newValue === InlichtingenIndicator.JA && inlichtingInput.value.planfase == null) {
        inlichtingInput.value.planfase = {} as Planfase
        inlichtingInput.value.externeDocumentatie = []
      }
    }, { immediate: true })

    return {
      vipInlichting,
      inlichtingInput,
      validationErrors,
      saving,
      accordion,
      refs,
      inlichtingKey,
      canEdit,
      addedBijlagen,
      inlichtingType,
      inlichtingTitle,
      voorschriftSuggesties,
      planfaseOptions,
      isNietGekend,
      bijlageUploaded,
      inlichtingToggled,
      toggleIndicator,
      validateInput,
      validationFailed,
      saved,
      removed,
      cancelled,
      addVoorschrift,
      downloadUrl,
      bijlagenSaved,
      bijlageDeleted,
      updateDocumentaties,
      formatNumber,
      formatDate
    }
  }
})
