import { computed, defineComponent, PropType } from 'vue'
import {
  BronInformatie,
  DossierStatus,
  Inlichting,
  InlichtingenIndicator,

  InlichtingRubriek,
  RisicogrondInlichtingV2,
  RisicogrondInputV2,
  RisicogrondRubriekV2,
  TypeInlichting,
  VipInlichtingDiscriminator,
  VipInlichtingType
} from '@/infrastructure/bff-client/bff-client'
import VipInlichtingPreviewNew
  from '@/components/vip-inlichting/new-version/components/inlichting-preview/vip-inlichting-preview-new.vue'
import { inlichtingen } from '@/infrastructure/constants/inlichting-types-constants'
import { formatDate, formatNumber } from '@/infrastructure/filters/filters'
import VipInlichtingEdit
  from '@/components/vip-inlichting/new-version/components/inlichting-edit/vip-inlichting-edit.vue'
import useEditableInlichting from '@/components/vip-inlichting/new-version/composables/editable-inlichting.composable'
import EventBus from '@/infrastructure/events/event-bus'
import ContentCollection
  from '@/components/vip-inlichting/new-version/components/content-collection/content-collection.vue'
import RisicoRubriek
  from '@/components/vip-inlichting/new-version/components/inlichtingen/milieu/risicogrond/v2/risico-rubriek.vue'
import useRisicogrondLabels
  from '@/components/vip-inlichting/new-version/components/inlichtingen/milieu/risicogrond/v2/composable/risicogrond-labels.composable'
import VlIpproMap from '@/components/ippro/vl-ippro-map/vl-ippro-map'

function mapInlichtingToInput (vipInlichting?: RisicogrondInlichtingV2): RisicogrondInputV2 {
  return {
    inlichtingenIndicator: vipInlichting === null ? InlichtingenIndicator.NEE : vipInlichting.inlichtingenIndicator,
    discriminator: VipInlichtingDiscriminator.RISICOGROND_V2,
    inlichtingType: VipInlichtingType.RISICOGROND,
    rubrieken: vipInlichting?.rubrieken
  } as RisicogrondInputV2
}

function mapInputToInlichting (input: RisicogrondInputV2, inlichtingId: string): RisicogrondInlichtingV2 {
  return {
    inlichtingId,
    inlichtingenIndicator: input.inlichtingenIndicator as unknown as InlichtingenIndicator,
    inlichtingType: VipInlichtingType.RISICOGROND,
    version: 2,
    rubriek: InlichtingRubriek.MILIEU,
    bronInformatie: {
      informatieverstrekker: 'Lokaal bestuur',
      laatsteWijzigingTijdstip: new Date()
    } as BronInformatie,
    kaarten: [],
    rubrieken: input.rubrieken
  } as RisicogrondInlichtingV2
}

export default defineComponent({
  name: 'RisicogrondV2',
  components: { VlIpproMap, RisicoRubriek, ContentCollection, VipInlichtingEdit, VipInlichtingPreviewNew },
  props: {
    inlichting: {
      type: Object as PropType<Inlichting>,
      required: false
    },
    dossierId: {
      type: String,
      required: true
    },
    dossierStatus: {
      type: Number as PropType<DossierStatus>,
      required: false
    },
    canDelete: Boolean
  },
  setup (props, { emit }) {
    const { options, toelichting, getLabel } = useRisicogrondLabels()

    const {
      refs,
      inlichtingKey,
      vipInlichting,
      inlichtingInput,
      canEdit,
      validationErrors,
      accordion,
      saving,
      addedBijlagen,
      bijlageUploaded,
      toggleIndicator,
      inlichtingToggled,
      cancelEdit,
      saveInlichting,
      validationFailed,
      downloadUrl,
      bijlagenSaved,
      bijlageDeleted
    } = useEditableInlichting(
      props.inlichting ?? ({ inlichtingType: VipInlichtingType.RISICOGROND, version: 2 } as unknown as RisicogrondInlichtingV2),
      mapInlichtingToInput,
      mapInputToInlichting)

    const inlichtingType = TypeInlichting.RisicogrondV2
    const inlichtingTitle = computed(() => inlichtingen.find(inl => inl.inlichtingType === inlichtingType).label)

    const validateInput = (): Map<string, string> => {
      const errors = new Map<string, string>()
      inlichtingInput.value.rubrieken?.forEach((rubriek, index) => {
        if (!rubriek.omschrijving) {
          errors.set(`rubriek-${index}-omschrijving`, 'Omschrijving is verplicht')
        }
        if (!rubriek.rubrieknummer) {
          errors.set(`rubriek-${index}-rubrieknummer`, 'Rubriek is verplicht')
        }
        if (rubriek.vlareboCode == null) {
          errors.set(`rubriek-${index}-vlarebocode`, 'Vlarebocode is verplicht')
        }
        if (rubriek.startdatum == null || rubriek.startdatum === '') {
          errors.set(`rubriek-${index}-startdatum`, 'Startdatum is verplicht')
        }
      })
      return errors
    }

    const saved = (inlichtingId: string) => {
      saveInlichting(inlichtingId)
      emit('saved', vipInlichting.value)
      inlichtingToggled(true)
    }

    const removed = () => {
      emit('removed', vipInlichting.value.inlichtingId)
      EventBus.$emit('close-modal', 'vl-modal-backdrop')
    }

    const cancelled = () => {
      cancelEdit()
      emit('cancelled')
    }

    const handleRubriekAdded = () => {
      const input = structuredClone(inlichtingInput.value)
      if (input.rubrieken == null) { input.rubrieken = [] }
      input.rubrieken.push({} as RisicogrondRubriekV2)
      inlichtingInput.value = input
    }

    const handleRubriekUpdate = (updatedRubriek: { rubriek: RisicogrondRubriekV2, index: number }) => {
      const input = structuredClone(inlichtingInput.value)
      input.rubrieken[updatedRubriek.index] = updatedRubriek.rubriek
      inlichtingInput.value = input
    }

    const handleRubriekRemoved = (index: number) => {
      const input = structuredClone(inlichtingInput.value)
      input.rubrieken.splice(index, 1)
      inlichtingInput.value = input
      EventBus.$emit('close-modal', 'vl-modal-backdrop')
    }

    return {
      vipInlichting,
      inlichtingInput,
      validationErrors,
      saving,
      accordion,
      refs,
      inlichtingKey,
      canEdit,
      addedBijlagen,
      inlichtingType,
      inlichtingTitle,
      options,
      toelichting,
      getLabel,
      bijlageUploaded,
      inlichtingToggled,
      toggleIndicator,
      validationFailed,
      saved,
      removed,
      cancelled,
      downloadUrl,
      bijlagenSaved,
      bijlageDeleted,
      formatNumber,
      formatDate,
      validateInput,
      handleRubriekUpdate,
      handleRubriekAdded,
      handleRubriekRemoved
    }
  }
})
